<div>
<div class="chart-title-and-export">
  <div class="chart-title">
    <div class="chart_title_drilldown">{{ data.description }}</div>
  </div>
  <div class="export-button">
    <button mat-icon-button id="more_button" [matMenuTriggerFor]="exportDataPopUp">
      <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
    </button>
    <mat-menu #exportDataPopUp="matMenu" id="more_menu">
      <button mat-menu-item (click)="downloadChart(drilldownDatasource.data)">
        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
        <span>Save as Excel</span>
      </button>
    </mat-menu>
  </div>
</div>
<mat-divider></mat-divider>

 
    <div class="drilldown-table">
        <table mat-table [dataSource]="drilldownDatasource" #drilldownMatSort="matSort" matSort>

            <ng-container *ngFor="let tColumn of drilldownColumns" matColumnDef="{{tColumn.key}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{tColumn.description}}">{{tColumn.name}}</th>
                <td mat-cell *matCellDef="let object">
                    {{object[tColumn.name] | conditionalNumberFormat}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="drilldownColumnsKeys; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: drilldownColumnsKeys;"></tr>
        </table>
    </div>
    <!-- <loading-spinner *ngIf="isLoadingFactoryScanInfo"></loading-spinner> -->
    <mat-paginator #drilldownMatPaginator="matPaginator" [pageSizeOptions]="[6]" showFirstLastButtons></mat-paginator>
</div>