import { Component, Input, ViewChild, ViewEncapsulation, Renderer2, ElementRef, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { DisplayGrid, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType } from 'angular-gridster2';
import { Configs, DashboardQueryInterface } from './bhive-dashboard-config.interface';
import { BhiveDashboardItem } from './bhive-dashboard-item.interface';
import { BhiveDashboardService } from './bhive-dashboard.service';
import { DashboardBuilderJson } from './bhive-dashboard.interface';
import { DashboardFilterInterface } from './bhive-dashboard-config.interface';
import { MatDialog } from '@angular/material/dialog';
import { SaveDialog } from './confirm-dialog.component';
import { CompareDialog } from './compare-dialog.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Pipe, PipeTransform } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { BhiveDashboardItemComponent } from './bhive-dashboard-item.component';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any[] {
    if (!items || !searchTerm) {
      return items;
    }
    searchTerm = searchTerm.toLowerCase();
    if (items[0]?.Name) {
      return items.filter(item => item.Name.toLowerCase().includes(searchTerm));
    }
    if (items[0]?.queries) {
      let filteredItems: any[] = [];
      items.forEach(group => {
        const filteredQueries = group.queries.filter((query: any) => query.Name.toLowerCase().includes(searchTerm));
        if (filteredQueries.length > 0) {
          filteredItems.push({ name: group.name, queries: filteredQueries });
        }
      });
      return filteredItems;
    }
    return [];
  }

}

const GROUPED_BAR_CHART =
  `
  <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m30 30h-26a2 2 0 0 1 -2-2v-26h2v26h26z"/><path d="m10 16h2v10h-2z"/><path d="m7 22h2v4h-2z"/><path d="m26 8h2v18h-2z"/><path d="m23 14h2v12h-2z"/><path d="m15 12h2v14h-2z" transform="matrix(-1 0 0 -1 32 38)"/><path d="m18 18h2v8h-2z" transform="matrix(-1 0 0 -1 38 44)"/><path d="m0 0h32v32h-32z" fill="none"/></svg>
  `;

const RESET =
  `
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21 21L3 3V6.33726C3 6.58185 3 6.70414 3.02763 6.81923C3.05213 6.92127 3.09253 7.01881 3.14736 7.10828C3.2092 7.2092 3.29568 7.29568 3.46863 7.46863L9.53137 13.5314C9.70432 13.7043 9.7908 13.7908 9.85264 13.8917C9.90747 13.9812 9.94787 14.0787 9.97237 14.1808C10 14.2959 10 14.4182 10 14.6627V21L14 17V14M8.60139 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L16.8008 11.1992"  stroke-width="2" stroke-linecap="round" stroke="#59C4E3" stroke-linejoin="round"/>
  </svg>
  `;
const FILTER =
  `
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L14.4686 13.5314C14.2957 13.7043 14.2092 13.7908 14.1474 13.8917C14.0925 13.9812 14.0521 14.0787 14.0276 14.1808C14 14.2959 14 14.4182 14 14.6627V17L10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6Z" stroke-width="2" stroke-linecap="round" stroke="#59C4E3" stroke-linejoin="round"/>
  </svg>
  `;

@Component({
  selector: 'bhive-dashboard',
  templateUrl: `bhive-dashboard.component.html`,
  styleUrls: [`bhive-dashboard.scss`],
  encapsulation: ViewEncapsulation.None
})
export class BhiveDashboardComponent implements OnChanges {
  @ViewChild('groupingSubMenu') groupingSubMenu!: MatMenuTrigger;
  @ViewChildren(BhiveDashboardItemComponent) children: QueryList<BhiveDashboardItemComponent>;
  @Input() selectedDashboard: any;
  @Input() organizationid: any;
  @Output() onDashboardChanged = new EventEmitter<any>();

  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild('filterDrawer') public filterDrawer: MatDrawer;
  @ViewChild('dashElement') dashElement!: ElementRef;
  @ViewChild('dynamicInput', { read: ElementRef }) searchInput: ElementRef;


  private unsubscribe$ = new Subject<void>();
  private subscriptions$?: Subscription[] = [];
  width: number = 100;
  width2: number = 77;
  name: string = ""
  options: GridsterConfig = { gridType: GridType.Fit };
  configs: Configs = {
    queries: [],
    filters: [],
    standards: [],
    visuals: []
  };

  searchQuery: string;
  filteredQueries: DashboardQueryInterface[];
  groupedQueries: any;
  searchFilter: string;
  filteredFilters: DashboardFilterInterface[];
  isEditing: boolean = false;
  isFilterDrawerOpen: boolean = false;
  dashboardItems: Array<BhiveDashboardItem> = [];
  showComplianceFilter = false;

  visualControl: FormControl = new FormControl();
  validVisualCodes: Array<Object> = [];
  previewQueryPath: string = "";
  queryControl: FormControl<DashboardQueryInterface> = new FormControl();
  queryDescription: string = "This text should describe what visual is about.";
  filterControl: FormControl = new FormControl();
  complianceControl: FormControl = new FormControl();


  chosenMenuFilters: Array<DashboardFilterInterface> = [];
  filtersSelectedData: Array<Object> = [];
  filterMenuControls: any = {};
  allFilters: any = {};
  selectedFilters: any[] = [];
  itemFilter: any = {};

  today = new Date();
  startDate = new UntypedFormControl(new Date(new Date().setMonth(this.today.getMonth() - 3)));
  endDate = new UntypedFormControl(new Date());
  startDateString: string;
  endDateString: string;
  fontSize: number;
  target = new FormControl();


  dashboardJSon: DashboardBuilderJson;

  private addingMode = false;

  constructor(private cubejs: BhiveDashboardService, private dialog: MatDialog, private renderer: Renderer2, private changeDetector: ChangeDetectorRef, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('grouped_bar_chart', sanitizer.bypassSecurityTrustHtml(GROUPED_BAR_CHART));
    iconRegistry.addSvgIconLiteral('reset', sanitizer.bypassSecurityTrustHtml(RESET));
    iconRegistry.addSvgIconLiteral('filter', sanitizer.bypassSecurityTrustHtml(FILTER));
  }

  resetProperties() {
    this.filterControl.reset();
    this.options = {}
    this.width = 100;
    this.name = ""
    this.configs = {
      queries: [],
      filters: [],
      standards: [],
      visuals: []
    };
    if (this.isEditing) {
      this.drawer.toggle()
      if (this.options.api && this.options.api.optionsChanged) {
        this.options.api.optionsChanged();
      }
      window.dispatchEvent(new Event('resize'));
    }
    this.addingMode = false;
    this.isEditing = false;
    this.dashboardItems = [];
    this.showComplianceFilter = false;

    this.visualControl = new FormControl();
    this.previewQueryPath = "";
    this.queryControl = new FormControl();
    this.queryDescription = "This text should describe what visual is about.";
    this.filterControl = new FormControl();
    this.complianceControl = new FormControl();


    this.chosenMenuFilters = [];
    this.filtersSelectedData = [];
    this.filterMenuControls = {};
    this.allFilters = {};
    this.selectedFilters = [];
    this.itemFilter = {};

    this.today = new Date();
    var startDate = new Date(this.today.getFullYear(), this.today.getMonth() - 3, 1);
    this.startDate = new UntypedFormControl(startDate);
    this.endDate = new UntypedFormControl(new Date());
    this.startDateString = "";
    this.endDateString = "";

    this.dashboardJSon = new DashboardBuilderJson();
  }
  clearFilter: boolean = false;
  cancelRequests() {
    this.subscriptions$?.forEach(subscription => subscription.unsubscribe());
    this.subscriptions$ = [];
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.cancelRequests();
    this.resetProperties();
    this.options = this.getDefaultGridsterConfig();
    this.loadDashboard();
  }

  loadDashboard() {
    this.cancelRequests();
    if (this.selectedDashboard['id'] != 0) {
      this.cubejs.getDashboard(this.selectedDashboard['is_home'], this.selectedDashboard['id']).pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          this.extractDashboardData(data);
          this.options.itemResizeCallback = this.itemResize.bind(this);
          window.dispatchEvent(new Event('resize'));
        })
    } 
  }

  private extractDashboardData(data: any) {
    this.dashboardJSon.name = data.dash[0]['name'];
    this.dashboardJSon.isHome = data.dash[0]['is_home'];
    this.dashboardJSon.id = data.dash[0]['id'];
    let db = JSON.parse(data.dash[0]['dashboard_json'])
    this.dashboardJSon.filters = data.dash[0]['filters'];
    this.dashboardJSon.items = db.items;
    this.dashboardJSon.filters.forEach((f: any) => {
      let filter: DashboardFilterInterface = {
        Id: f[0]['id'],
        Type: f[0]['type'],
        ShowName: f[0]['name'],
        Name: f[0]['cube_filter_name'],
        QueryJSON: f[0]['query_json']
      }
      let filterSelectedValues = db.filters.find((el: any) => el.filterId === filter.Id).selectedValues;
      this.addFilterToMenu(filter);
      this.setFilterValue(filter.ShowName, filterSelectedValues);
    })
    this.applyFilters();
    db.items.forEach((itemConf: any) => {
      let queryConf = data.dash[0]['items'].find((el: any) => el['id'] === itemConf.queryId && el['code'] === itemConf.visualCode);
      if (queryConf) {
        let queryObj: DashboardQueryInterface = this.createDashboardQuery(queryConf);
        this.addItem(itemConf, queryObj);
      }
    })

  }

  private createDashboardQuery(queryConf: any): DashboardQueryInterface {
    return {
      Id: queryConf['id'],
      Name: queryConf['name'],
      Description: queryConf['description'],
      QueryJSON: queryConf['query_json'],
      Active: queryConf['active'],
      VisualType: queryConf['default_visual_type_id'],
      NeedsCompliance: queryConf['needs_compliance'],
      RawQueryId: queryConf['raw_query_id'],
      DrillQueryId: queryConf['drill_query_id'],
      HasTarget: queryConf['has_target'],
      CanCompare: queryConf['can_compare'],
      FilterConfig: JSON.parse(queryConf['filter_config']),
      GetLatestChemicals: queryConf['get_latest_chemicals'],
      Configs: [
        {
          Code: queryConf['code'],
          QueryPivotConfig: JSON.parse(queryConf['query_pivot_config']),
          Groupings: JSON.parse(queryConf['groupings'])
        }
      ]
    };
  }

  private getDefaultGridsterConfig(): GridsterConfig {
    // Return your default Gridster configuration here
    return {
      gridType: GridType.ScrollVertical,
      margin: 6,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      useBodyForBreakpoint: false,
      minCols: 1,
      maxCols: 100,
      minRows: 1,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
        ignoreContentClass: 'gridster-item-content',
        ignoreContent: false,
      },
      resizable: {
        enabled: false
      },
      swap: false,
      pushItems: false,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: true,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      // scrollToNewItems: true
    };
  }
  groupBy(item: BhiveDashboardItem, grouping: string): void {
    const index = item['selectedGroups'].indexOf(grouping);
    const updatedData = { ...item.data };
    const query = JSON.parse(updatedData.QueryJSON);
    if (index === -1) {
      // If the group is not already selected, add it
      item['selectedGroups'].push(grouping);

      query['dimensions'].unshift(grouping);
      updatedData.QueryJSON = JSON.stringify(query);
    } else {
      // If the group is already selected, remove it
      item['selectedGroups'].splice(index, 1);
      const dimensionIndex = query['dimensions'].indexOf(grouping);
      if (dimensionIndex !== -1) {
        query['dimensions'].splice(dimensionIndex, 1);
      }
      updatedData.QueryJSON = JSON.stringify(query);
    }
    this.dashboardItems = this.dashboardItems.map(dashboardItem =>
      dashboardItem === item ? { ...dashboardItem, data: updatedData } : dashboardItem
    );
  }
  initializeNewDashboard(): void {
    this.resetProperties();
    this.addingMode = true;
    this.isEditing = true;
    this.width = 77;
    this.drawer.toggle();
    this.configureGridster();
    this.fetchDashboardConfigs();
    window.dispatchEvent(new Event('resize'));
  }

  initializeDashboardEditing(): void {
    this.isEditing = true;
    this.width = 77;
    this.drawer.toggle();
    this.configureGridster();
    this.fetchDashboardConfigs();
    window.dispatchEvent(new Event('resize'));
    // this.applyFilters()
  }

  configureGridster(): void {
    this.options.draggable = {
      enabled: true,
      ignoreContentClass: 'gridster-item-content',
      ignoreContent: false,
    };
    this.options.swap = true;
    this.options.margin = 10;
    this.options.itemResizeCallback = this.itemResize.bind(this);
    this.options.gridType = GridType.ScrollVertical;
    this.options.resizable = { enabled: true };
    this.updateGridster();
  }

  updateGridster(): void {
    if (this.options.api) {
      if (this.options.api.optionsChanged)
        this.options.api.optionsChanged();
      if (this.options.api.resize)
        this.options.api.resize();
    }
  }

  fetchDashboardConfigs(): void {
    this.cubejs.getDashboardConfigs().pipe(takeUntil(this.unsubscribe$)).subscribe((data: Configs) => {
      this.configs = data;
      this.filteredQueries = this.configs['queries'].sort((a, b) => a.Name.localeCompare(b.Name)); // Sort alphabetically
      this.groupedQueries = [
        {
          name: 'Chemical Products',
          queries: this.filteredQueries.filter(query => query.Name.includes('Chemical Products'))
        },
        {
          name: 'Compliance by Consumption',
          queries: this.filteredQueries.filter((query: any) => query.Name.includes('Compliance by Consumption'))
        },
        {
          name: 'Compliance by Count',
          queries: this.filteredQueries.filter((query: any) => query.Name.includes('Compliance by Count'))
        },
        {
          name: 'Higg',
          queries: this.filteredQueries.filter((query: any) => query.Name.includes('Higgs'))
        },
        {
          name: 'Wastewater',
          queries: this.filteredQueries.filter((query: any) => query.Name.includes('Wastewater') || query.Name.includes('Waste Water'))
        },
        {
          name: 'Others',
          queries: this.filteredQueries.filter((query: any) => !query.Name.includes('Chemical Products') && !query.Name.includes('Compliance by Consumption') &&
            !query.Name.includes('Compliance by Count') && !query.Name.includes('Higgs') && !query.Name.includes('Wastewater') && !query.Name.includes('Waste Water'))
        }
      ];
      this.filteredFilters = this.configs['filters'];
    });
  }

  saveDashboard(): void {
    this.cubejs.saveDashboard(this.dashboardJSon).pipe(takeUntil(this.unsubscribe$)).subscribe((d: any) => {
      this.resetProperties();
      this.disableGridster();
      this.updateGridster();
      window.dispatchEvent(new Event('resize'));
      this.onDashboardChanged.emit(d['dashboard']);
    });
  }

  openSaveDialog(): void {
    this.prepareDashboardForSave();

    const dialogRef = this.dialog.open(SaveDialog, {
      width: '40%',
      data: { dbJson: this.dashboardJSon, addingMode: this.addingMode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboardJSon.name = result.dbJson.name;
        this.dashboardJSon.isHome = result.dbJson.isHome;
        this.saveDashboard();
      }
    });
  }

  prepareDashboardForSave(): void {
    this.dashboardJSon.filters = []
    this.dashboardJSon.filters = this.chosenMenuFilters.map(el => ({
      filterId: el.Id,
      selectedValues: this.filterMenuControls[el['ShowName']].value
    }));
    this.dashboardJSon.items = []
    this.dashboardJSon.items = this.dashboardItems.map(el => ({
      queryId: el.data.Id,
      positionX: el.gridItem.x,
      positionY: el.gridItem.y,
      cols: el.gridItem.cols,
      rows: el.gridItem.rows,
      visualCode: el.visualCode
    }));
  }

  closeDashboard(): void {
    this.isEditing = false;
    this.drawer.toggle()
    this.resetProperties();
    this.disableGridster();
    this.updateGridster();
    window.dispatchEvent(new Event('resize'));
    this.loadDashboard();
  }

  closeFilterMenu(): void {
    this.filterDrawer.toggle();
    this.width2 = !this.filterDrawer.opened ? 100 : 77;
    this.updateGridster();
  }

  disableGridster(): void {
    this.options.draggable = { enabled: false };
    this.options.swap = false;
  }

  itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    const dashItem = this.findDashboardItem(itemComponent.item.x, itemComponent.item.y);

    if (dashItem) {
      const titleHeight = this.getTitleHeight(dashItem);
      dashItem.width = itemComponent.width - 40;
      dashItem.height = itemComponent.height - titleHeight - 21;
    }

    window.dispatchEvent(new Event('resize'));
  }

  findDashboardItem(x: number, y: number): BhiveDashboardItem | undefined {
    return this.dashboardItems.find(item =>
      item.gridItem.x === x && item.gridItem.y === y
    );
  }

  getTitleHeight(dashboardItem: BhiveDashboardItem): number {
    const elementId = dashboardItem.data.Id + '_' + dashboardItem.visualCode;
    const titleHeight = document.getElementById(elementId)?.children[0].getBoundingClientRect().height || 0;
    return titleHeight;
  }

  calculateFontSize(width: number | undefined, height: number | undefined) {
    return this.cubejs.calculateFontSize(width, height, 0.08, 22, 15, 'px')
  }

  addItem(itemOptions?: any, queryObj?: DashboardQueryInterface): void {
    const newItem = this.createDashboardItem(itemOptions, queryObj);
    this.dashboardItems.push(newItem);
  }

  createDashboardItem(itemOptions?: any, queryObj?: DashboardQueryInterface): BhiveDashboardItem {
    if (itemOptions) {
      return {
        gridItem: {
          cols: itemOptions.cols,
          rows: itemOptions.rows,
          y: itemOptions.positionY,
          x: itemOptions.positionX,
        },
        data: queryObj!,
        visualCode: itemOptions.visualCode,
        filters: this.filtersSelectedData,
        groupings: queryObj?.Configs.find(g => g.Code === itemOptions.visualCode)?.Groupings || [],
        selectedGroups: [] // Initialize selectedGroups as an empty array
      };
    } else {
      const vCode = this.configs['visuals'].find(el => el.Id == this.visualControl.value)?.Code || '';
      return {
        gridItem: {
          cols: 2,
          rows: 1,
          y: 0,
          x: 0,
        },
        data: this.queryControl.value,
        visualCode: vCode,
        filters: this.filtersSelectedData,
        groupings: this.queryControl.value.Configs.find(g => g.Code === vCode)?.Groupings || [],
        selectedGroups: [] // Initialize selectedGroups as an empty array
      };
    }
  }

  removeItem(item: BhiveDashboardItem): void {
    const index = this.dashboardItems.indexOf(item);
    if (index !== -1) {
      this.dashboardItems.splice(index, 1);
    }
  }


  // Function to handle query selection
  onQuerySelection(event: any) {
    if (event.isUserInput) {
      const query = event.source.value;
      this.validVisualCodes = query.Configs
        .filter((config: any) => config.QueryPivotConfig) // Filter only configs with QueryPivotConfig
        .map((config: any) => config.Code);
      this.queryDescription = query['Description'];
      this.showComplianceFilter = query.NeedsCompliance;
      this.visualControl.setValue(query.VisualType);
      this.showPreview();
    }
  }


  // Function to show a preview of the selected query
  showPreview() {
    this.previewQueryPath = '';
    const visualCode = this.configs.visuals.find(el => el.Id == this.visualControl.value)!.Code;

    if (this.queryControl.value != null) {
      this.cubejs.getVisualPreview(this.queryControl.value, visualCode).then(result => {
        if (result['image'].length !== 0) {
          this.previewQueryPath = result['image'][0]['preview_url'];
        }
      });
    } else {
      this.queryControl.valueChanges.subscribe(val => {
        this.showPreview();
      });
    }
  }

  // Function to handle filter selection
  onFilterSelection(event: any) {
    const filter: DashboardFilterInterface = event.source.value;

    if (event.source._selected) {
      if (!this.filterDrawer.opened) {
        this.filterDrawer.toggle();
      }
      this.addFilterToMenu(filter);
    } else {
      this.chosenMenuFilters = this.chosenMenuFilters.filter(obj => obj.Name != filter.Name);
    }
  }

  // Function to add a filter to the menu
  addFilterToMenu(filter: DashboardFilterInterface) {
    if (!this.chosenMenuFilters.find(obj => obj.Name == filter.Name)) {
      if (filter['ShowName'] != 'Brand Scope')
        this.filterMenuControls[filter['ShowName']] = new FormControl();
      else this.filterMenuControls[filter['ShowName']] = new FormControl(true);
      this.subscriptions$?.push(this.cubejs.fillFilterWithData(filter).subscribe((res) => {
        filter.Data = res;
        this.chosenMenuFilters.push(filter);
        this.allFilters[filter['ShowName']] = filter.Data;
        this.itemFilter[filter['ShowName']] = this.allFilters[filter['ShowName']]
      }));
    }
  }

  resetFilters() {
    this.chosenMenuFilters.forEach(filterElement => {
      this.filterMenuControls[filterElement['ShowName']].reset();
    });
    this.selectedFilters = [];
    var startDate = new Date(this.today.getFullYear(), this.today.getMonth() - 3, 1);
    this.startDate = new UntypedFormControl(startDate);
    this.endDate = new UntypedFormControl(new Date());
    this.applyFilters();
  }

  // Function to apply selected filters
  applyFilters() {
    this.filtersSelectedData = [];

    for (let filterKey in this.filterMenuControls) {//foreach key in filter Controls
      const objValue = this.filterMenuControls[filterKey].value;//gets values of filter

      if (filterKey == "Standards") {
        this.cubejs.changeCompliance(objValue);
      } else {
        if (objValue instanceof Array) {//if is multiple selection
          if (objValue.length !== 0) {
            this.filtersSelectedData.push({ [filterKey]: objValue, meta: this.chosenMenuFilters.find(f => f.ShowName === filterKey)?.Name });//push selected values to an array
          }
        } else {//single value selected
          if (objValue != null) {
            this.filtersSelectedData.push({ [filterKey]: objValue });
          }
        }
      }
    }

    const std = new Date(this.startDate.value);
    const edd = new Date(this.endDate.value);
    this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    this.filtersSelectedData.push({ "Date": [this.startDateString, this.endDateString], meta: this.chosenMenuFilters.find(f => f.ShowName === "scan_date")?.Name });

    this.dashboardItems.forEach(item => {
      item.filters = this.filtersSelectedData;
    });
  }

  // Function to remove a filter
  removeFilter(event: any, index: number) {
    this.chosenMenuFilters.splice(index, 1);
    event.currentTarget.parentElement.remove();
  }

  // Function to set the value of a filter
  setFilterValue(key: string, value: any) {
    this.filterMenuControls[key].setValue(value);
  }

  // Function to set the value of a query
  setQueryValue(value: any) {
    this.queryControl.setValue(value);
  }

  openCompareDialog(item: BhiveDashboardItem): void {
    let itemClone = JSON.parse(JSON.stringify(item));
    const dialogRef = this.dialog.open(CompareDialog, {
      width: '60%',
      data:  itemClone 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  downloadReport() {
    const newArray = JSON.parse(JSON.stringify(this.dashboardItems));
    newArray.map((el: any) => {
      el.htmlEl = document.getElementById(el.data.Id + '_' + el.visualCode)
    })
    // get size of report page
    let reportPageHeight: number = document.getElementById('dash')!.clientHeight;
    let reportPageWidth: number = document.getElementById('dash')!.clientWidth;

    const maxCol: number = Math.max.apply(Math, this.dashboardItems.map(function (o) { return o.gridItem.y; }));
    const maxRow: number = Math.max.apply(Math, this.dashboardItems.map(function (o) { return o.gridItem.x; }));
    const colWidth: number = reportPageWidth / maxCol;
    const rowHeight: number = reportPageHeight / maxRow;


    // create a new canvas object that we will populate with all other canvas objects
    let pdfCanvas = this.renderer.createElement('canvas')
    this.renderer.setProperty(pdfCanvas, 'id', 'canvaspdf');
    this.renderer.setProperty(pdfCanvas, 'width', reportPageWidth);
    this.renderer.setProperty(pdfCanvas, 'height', reportPageHeight);

    // keep track canvas position
    var pdfctx = pdfCanvas.getContext('2d');
    var pdfctxX = 0;
    var pdfctxY = 0;
    var buffer = 100;
    // let dashItemsElement = Array.from(document.getElementsByName('dash_item'))
    // let dh = document.getElementById('dash');
    const tasks = newArray.map((tab: any) => html2canvas(tab.htmlEl))

    Promise.all(tasks).then(canvases => {
      for (const canvas of canvases) {
        let canvasHeight = canvas.height / 2;
        let canvasWidth = canvas.width / 3;
        pdfctx.drawImage(canvas, pdfctxX, pdfctxY, canvasWidth, canvasHeight);
        pdfctxX = 0;// += canvasWidth! + buffer;
        pdfctxY += canvasHeight! + buffer;

        // our report page is in a grid pattern so replicate that in the new canvas
        // if (item.gridItem.x % 2 === 1) {
        //   pdfctxX = 0;
        //   pdfctxY += canvasHeight! + buffer;
        // }
      }
      let pdf = new jsPDF('p', 'px', [reportPageWidth!, reportPageHeight!]);
      pdf.addImage(pdfCanvas, 'PNG', 0, 0, reportPageWidth!, reportPageHeight!);

      // download the pdf
      pdf.save("Download.pdf");
    });
  }

  exportRawData() { }

  downloadChart(item: DashboardQueryInterface) {
    this.cubejs.exportData('RAW', item, this.filtersSelectedData)
  }
  setTarget(item: any) {
    console.log(item);
    this.children.forEach((child: BhiveDashboardItemComponent) => {
      console.log(child);
      if (this.deepEqual(child.data, item.data)) {
        child.setTarget(this.target.value!);
      }
    });
    this.cubejs.saveQueryTarget(item.data.Id, this.target.value!).subscribe()
  }
  onOpenChange(type: string) {
    this.searchInput.nativeElement.value = "";

    const selectedFilterValues = this.selectedFilters
      .filter((filter: any) => filter.type === type)
      .map((filter: any) => filter.value);
    this.itemFilter[type] = this.allFilters[type];

    this.filterMenuControls[type].setValue(selectedFilterValues, { emitEvent: false });
  }

  onInputChange(event: any, type: string) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput == "") {
      const selectedFilterValues = this.selectedFilters
        .filter((filter: any) => filter.type === type)
        .map((filter: any) => filter.value);
      this.filterMenuControls[type].setValue(selectedFilterValues, { emitEvent: false });
      this.itemFilter[type] = this.allFilters[type];
    }
    else {
      this.itemFilter[type] = this.allFilters[type].filter((name: any) => {
        const prov = name[type].toLowerCase();
        return prov.includes(searchInput);
      });
    }

  }

  selection(event: any, type: string) {
    if (event.isUserInput) {
      if (event.source._selected) {
        // Add a new object to selectedFilters with 'type' and 'value' properties
        this.selectedFilters.push({ type: type, value: event.source.value });
      } else {
        // Remove the element with matching 'value' and 'type'
        this.selectedFilters = this.selectedFilters.filter((el: any) => {
          return el.value !== event.source.value || el.type !== type;
        });
      }
    }
  }
  deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
  // async creatPdf({
  //   doc,
  //   elements,
  // }: {
  //   doc: jsPDF;
  //   elements: HTMLCollectionOf<Element>;
  // }) {
  //   let top = 20;
  //   const padding = 10;

  //   for (let i = 0; i < elements.length; i++) {
  //     const el = elements.item(i) as HTMLElement;
  //     const imgData = await htmlToImage.toPng(el);

  //     let elHeight = el.offsetHeight;
  //     let elWidth = el.offsetWidth;

  //     const pageWidth = doc.internal.pageSize.getWidth();

  //     if (elWidth > pageWidth) {
  //       const ratio = pageWidth / elWidth;
  //       elHeight = elHeight * ratio - padding;
  //       elWidth = elWidth * ratio - padding;
  //     }

  //     const pageHeight = doc.internal.pageSize.getHeight();

  //     if (top + elHeight > pageHeight) {
  //       doc.addPage();
  //       top = 20;
  //     }

  //     doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
  //     top += elHeight;
  //   }
  // }
}