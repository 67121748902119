<div class="  inset-0">
    <!-- Main -->
    <div class="flex flex-col flex-auto  h-full  ">

        <div *ngIf="!isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">

            New Platform Feature : SDS Screening functionality is now available for ALDI partners!
        </div>

        <div *ngIf="isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">

            平台的新功能：ALDI合作伙伴可以使用SDS筛查功能啦！
        </div>

        <br>
        <p *ngIf="!isChineseSystem()" class="pl-6 pr-6   tracking-tight ">
            This feature enables you to request a screening of your unverified chemicals by uploading the SDS file.
            Please find the guideline under this <a href="https://cloud.goblu.net/s/mfaGb2He6NCyiHt"
                target="_blank">link</a>.

            For any questions please contact: <a href="mailto:support@thebhive.net"
                class="contact-email">support@thebhive.net</a>
        </p>
        <!-- <br>https://cloud.goblu.net/s/8Ti8GaQPdk847JT
        <ul *ngIf="!isChineseSystem()" class="ml-12 pl-6 pr-6 tracking-tight">
            <li>you update a chemical product’s information in My ChemStore or in Step 6 of the Inventory Upload Flow.
            </li>
            <li>you want to mark a chemical inventory as „complete”.</li>
        </ul> -->


        <p *ngIf="isChineseSystem()" class="pl-6 pr-6  tracking-tight ">
            此功能使您能够通过上传SDS文件来请求对未经验证的化学品进行筛选。请在此<a href="https://cloud.goblu.net/s/8Ti8GaQPdk847JT"
                target="_blank">链接下找到指南</a>。如有任何问题，请联系：
            <a href="mailto:support@thebhive.net" class="contact-email">support@thebhive.net</a>
        </p>
        <!-- <br>
        <ul *ngIf="isChineseSystem()" class="ml-12 pl-6 pr-6 tracking-tight">
            <li> 当您在 "我的ChemStore" 更新化学品信息， 或"库存上传流程 "的第6步更新化学产品信息时；</li>
            <li> 当您将化学库存清单标记为 "完整 "时。</li>
        </ul> -->

        <!-- <p *ngIf="!isChineseSystem()" class="pl-6 pr-6 font-semibold tracking-tight ">
            We have implemented a small feature to support you in filling out all necessary information: If you try to
            mark your chemical inventory as complete and the „General Usage in Factory” for some products is still
            missing, you can easily fill in the information right there in the pop-up window all in one go. For any
            questions, please contact: <a href="mailto:support@thebhive.net"
                class="contact-email">support@thebhive.net</a>

        </p>
        <p *ngIf=" isChineseSystem()" class="pl-6 pr-6 font-semibold tracking-tight ">
            我们提供了一个小功能，帮助您填写所有必要信息： 如果您尝试将化学品库存标记为 "完整"，但仍缺少某些产品的 "一般用途"，会有弹窗出现，您可以在弹窗中一次性填写所有信息。
            如有任何问题，请联系： <a href="mailto:support@thebhive.net" class="contact-email">support@thebhive.net</a>
        </p>
        <br> -->
        <br>

        <div class="flex" style="justify-content: center;">
            <button class="  mt-4   choose-button " (click)="dialogRef.close(true)">
                {{isChineseSystem()?'确认知悉！':'Got it! '}}
            </button>
            &nbsp; &nbsp;


            <button class="mt-4 btn btn-primary" (click)="dialogRef.close(false)">
                {{isChineseSystem()?'请再次提醒我。':'Remind me again'}}
            </button>
        </div>
    </div>