import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentInit, AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';


export const CONDITIONS_LIST = [
  { value: "is-equal", label: "Is equal" },
  { value: "is-not-equal", label: "Is not equal" },
  { value: "contains", label: "Contains" },
  { value: "does-not-contain", label: "Does not contain" }
];

export const CONDITIONS_FUNCTIONS = {
  // search method base on conditions list value
  "is-equal": function (value: any, filterdValue: any) {
    return value == filterdValue;
  },
  "is-not-equal": function (value: any, filterdValue: any) {
    return value != filterdValue;
  },
  "contains": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return value.includes(filterdValue);
    }
    return false;
  },
  "does-not-contain": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return !value.includes(filterdValue);
    }
    return false;
  },
};

@Component({
  selector: 'lib-table-filter-headers',
  templateUrl: './table-filter-headers.component.html',
})
export class TableFilterHeadersComponent implements OnInit {

  @Input() columnName: string = '';
  @Input() dataSource: any;
  public conditionsList = CONDITIONS_LIST;
  public searchValue: any = {};
  public searchCondition: any = {};
  private _filterMethods = CONDITIONS_FUNCTIONS;
  filteredItems: any[] = []
  searchText: string = '';
  uniqueItems: any[] = [];
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'value'];
  selectedValues: any[] = []
  originalData: any[] = []
  @ViewChild('select') select: MatSelect;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  tableDataSource = new MatTableDataSource<any>();
  currentSortDirection: string = '';

  constructor() {  }

  ngOnInit() {
    this.fillData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillData();

  }

  fillData(): void {
    if (this.dataSource)
      this.originalData = [...this.dataSource.data];
      this.removeDuplicates();
      this.dataSource.filterPredicate = (p: any, filtre: any) => {
        let result = true;
        let keys = Object.keys(p); // keys of the object data

        for (const key of keys) {
          let searchCondition = filtre.conditions[key]; // get search filter method

          if (searchCondition && searchCondition !== "none") {
            if (
              filtre.methods[searchCondition](p[key], filtre.values[key]) ===
              false
            ) {
              // invoke search filter
              result = false; // if one of the filters method not succeed the row will be remove from the filter result
              break;
            }
          }
        }

        return result;
      };
  }

  //function removes the duplicated values and create a list with unique values used in the search table (checkboxes)
  removeDuplicates(): void {
    const uniqueSet = new Set();
    this.uniqueItems = this.dataSource.data.map((item: any) => {
      const value = item[this.columnName];

      if (!uniqueSet.has(value)) {
        uniqueSet.add(value);
        return { value };
      }

      return null;
    }).filter(Boolean); // Filter out null values
    this.filteredItems = [...this.uniqueItems];
  }

  //function filters the checkboxes based on the search values
  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput == "") {
      this.filteredItems = this.uniqueItems;
    }
    else {
      this.filteredItems = this.uniqueItems.filter(({ value }) => {

        const prov = typeof (value) == 'number' ? value : value?.toLowerCase();
        return prov ? (typeof (value) == 'number' ? prov.toString().includes(searchInput) : prov.includes(searchInput)) : false;
      });
    }
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredItems.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedValues = [];
    } 
    else {
      this.filteredItems.forEach((row: any) => this.selection.select(row));
      this.selectedValues = this.filteredItems;
    }
  }


  applyFilter() {
    let searchFilter: any;
    const selectedItems = this.selection.selected;

    if (selectedItems.length > 0) { //if there are selected checkboxes
      const filteredData = this.originalData.filter((item: any) => {
        const columnValue = item[this.columnName];
        return selectedItems.some(selectedValue => selectedValue.value === columnValue);
      });
      this.dataSource.data = filteredData;
    }
    else {
      searchFilter = {
        values: this.searchValue,
        conditions: this.searchCondition,
        methods: this._filterMethods,
      };
    this.dataSource.filter = searchFilter;
    }
  }


  clearColumn(columnKey: string): void {
    this.searchValue[columnKey] = null;
    this.searchCondition[columnKey] = "none";
    this.selection.clear();
    this.dataSource.data = this.originalData;
  }
  closeMenu() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  sortData(direction: 'asc' | 'desc', columnName: string): void {
    this.currentSortDirection = direction;
    const sortDirection = this.currentSortDirection;
    if (sortDirection === 'asc') {
      this.dataSource.filteredData.sort((a: any, b: any) => {
        let valueA = a[columnName];
        let valueB = b[columnName];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return valueA - valueB;
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        } else {
          // Handle mixed types or other cases here
          return 0;
        }
      });
    } else {
      this.dataSource.filteredData.sort((a: any, b: any) => {
        let valueA = a[columnName];
        let valueB = b[columnName];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return valueB - valueA;
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
          if (valueA < valueB) return 1;
          if (valueA > valueB) return -1;
          return 0;
        } else {
          // Handle mixed types or other cases here
          return 0;
        }
      });
    }
    this.dataSource._updateChangeSubscription();
    this.closeMenu();
  }

}


