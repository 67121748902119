import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DocumentcenterComponent } from '../documentcenter.component';

export interface ConsumptionData {
  id?: number;
  year?: string;
  amount?: string;
  amountUnit?: string;
  timeUnit?: string;
  source?: Object;
  comment?: string;
  topic?: string;
  file?: File;
}

@Component({
  selector: 'app-consumption-data-upload',
  templateUrl: './consumption-data-upload.component.html',
  styleUrls: ['./consumption-data-upload.component.css']
})
export class ConsumptionDataUploadComponent implements OnInit {
  consumption: ConsumptionData;
  alertMsgs = {};
  sources = [];

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DocumentcenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    this.fetchTranslations();
    this.translateService.onLangChange.subscribe(() => {
      // this.loading = true;
      this.fetchTranslations();
    });
  }

  ngOnInit() {
    // console.log(this.data);
    if ( this.data["consumption"] ) {
      this.consumption = this.data["consumption"];
    } else {
      this.initConsumption();
    }

    this.sources = this.data["sources"];
    // console.log(this.sources);
  }

  addFile(files: FileList) {
    // console.log(files);
    let file: File = files[0];
    let uploadTypes = ['pdf'];
    // console.log(file);
    let nameArray = file.name.split('.');
    let type = nameArray[nameArray.length - 1];
    // console.log(type);
    // console.log(uploadTypes.includes(type));
    if ( !uploadTypes.includes(type) ) {
      alert(this.alertMsgs["FILE_TYPE"]);
      this.clearContent();
    } else {
      this.consumption.file = file; 
    }

    // console.log(this.document);
  }

  clearContent() {
    var elements = document.getElementsByTagName("input");
    for (var ii=0; ii < elements.length; ii++) {
      if(elements[ii].type.toLowerCase() == 'file'){
         elements[ii].value = "";
      }
    }
    this.consumption.file = null;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  upload() {
    // console.log(this.consumption);
    if ( this.consumption.year == "") {
      alert(this.alertMsgs["YEAR"]);
    } else if ( this.consumption.amount == "") {
      alert(this.alertMsgs["AMOUNT"]);
    } else if ( this.consumption.amountUnit == "" ) {
      alert(this.alertMsgs["AMOUNT_UNIT"]);
    } else if ( this.consumption.timeUnit == "" ) {
      alert(this.alertMsgs["TIME_UNIT"]);
    } else if ( this.consumption.source == "" ) {
      alert(this.alertMsgs["SOURCE"]);
    // } else if ( !this.consumption.file ) {
    //   alert(this.alertMsgs["FILE_SELECT"]);
    } else {
      // console.log(this.document);
      this.dialogRef.close(this.consumption);
    }

  }

  initConsumption() {
    this.consumption = {year: "", amount: "", amountUnit: "", timeUnit: "", source: { english: "", translation: ""}, comment: "", topic: "", file: null};
  }

  fetchTranslations() {
    this.translateService.get("CONSUMPTION_UPLOAD_COMPONENT.ALERTS").subscribe( (response) =>{
      this.alertMsgs = response;
      // console.log(this.alertMsgs);
    });
  }

  hasFile() {
    // console.log(this.consumption.file);
    return this.consumption.file;
  }

}
