import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.openUserGuide()
  }
  openUserGuide() {
    const url = "https://www.thebhive.net/user-guide-languages"
    window.open(url, "_blank");

  }
}
