import { Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BhiveDashboardService } from './bhive-dashboard.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'bhive-dashboard-builder',
  templateUrl: `bhive-dashboard-base.component.html`,
  styleUrls: [`bhive-dashboard.scss`],
  encapsulation: ViewEncapsulation.None
})
export class BhiveDashboardBaseComponent implements OnInit {
  @Input() isHome: boolean = true;
  code: number | null = 0;
  selectedDashboard: any;
  dashboardList: Array<any> = [];
  dashboardControl = new FormControl();
  organization_id: number;
  
  constructor(private cubejs: BhiveDashboardService) { }

  ngOnInit(): void {
    //get dashboard
    this.cubejs.getAllDashboards().subscribe((res: any) => {
      if (res['dash'].length !== 0) {
          this.organization_id = res['dash'][0].organization_id
          this.dashboardList = [...res['dash']];
          this.selectedDashboard = this.dashboardList.find(item => item['is_home'] === true);
          this.dashboardControl.setValue(this.selectedDashboard['id'])
          this.code = this.selectedDashboard['id'];
          this.isHome = this.selectedDashboard['is_home'];
        }
        else{
          this.selectedDashboard = { id: 0, is_home: true}
          this.dashboardControl.setValue(this.selectedDashboard['id'])
          this.code = this.selectedDashboard['id'];;
          this.isHome = false;
        }
      })
  }
  onDashboardChange(event: any) {
        if (event.isUserInput) {
      let id = event.source.value;
      this.selectedDashboard = this.dashboardList.find(item => item['id'] === id);
      this.code = this.selectedDashboard['id'];
      this.isHome = this.selectedDashboard['is_home'];
    }
  }

  refreshDashboardList(item: any) {
    this.cubejs.getAllDashboards().subscribe((res: any) => {
        this.dashboardList = [...res['dash']];
        this.selectedDashboard = { 
          id: item['id'], 
          organization_id: item.organization['id'], 
          name: item['name'], 
          is_home: item['isHome'] 
        };
        this.dashboardControl.setValue(this.selectedDashboard['id'])
        this.code = this.selectedDashboard['id'];
        this.isHome = this.selectedDashboard['is_home'];
      })
  }
}






