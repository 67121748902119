import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BhiveDashboardService } from './bhive-dashboard.service';

@Component({
    selector: 'drilldown-dialog',
    styleUrls: [`bhive-dashboard.scss`],
    templateUrl: 'drilldown-dialog.component.html',
})
export class DrillDownDialog implements OnInit {
    @ViewChild('drilldownMatPaginator') drilldownMatPaginator: MatPaginator;
    @ViewChild('drilldownMatSort') drilldownMatSort: MatSort;
    drilldownDatasource: MatTableDataSource<any> = new MatTableDataSource<any>();
    drilldownColumns: any[] = [];
    drilldownColumnsKeys: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<DrillDownDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cubejs: BhiveDashboardService
    ) { }

    ngOnInit(): void { 
        let dataTable = this.cubejs.translateColumns(this.data.table, this.drilldownColumns);
        this.drilldownColumns = dataTable.columns;
        this.drilldownColumnsKeys = dataTable.columns.map((column: any) => column.key);
        this.drilldownDatasource.data = dataTable.data;
        const meta = this.data.table.meta;
        this.drilldownColumns.forEach((tableColumn: any) => {
            const columnMeta = meta?.find((column: any) => column.key === tableColumn.key);

            if (columnMeta && columnMeta.meta) {
                // Add a 'description' property to the column
                tableColumn.description = columnMeta.meta.description;
            }
        });
        setTimeout(() => this.drilldownDatasource.paginator = this.drilldownMatPaginator);
        setTimeout(() => this.drilldownDatasource.sort = this.drilldownMatSort);
        
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    downloadChart(data: Array<any>) {
        this.cubejs.exportData('RAW_POPUP',undefined,undefined, data)
    }

}