import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { GlobalsService } from 'app/globals-services/globals.service';
import { LanguageService } from 'app/language/language.service'; 
import { UserRole } from 'app/user-interface';
import { KeycloakService } from 'keycloak-angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls:['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    selectedNavigation: any;
    user: User;
    role: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    isFactory: boolean=false;
    selectedLanugage;
    availableLanguages = [];
    unreadNotificationTotal=0
    /**
     * Constructor
     */
    constructor(
        // private _activatedRoute: ActivatedRoute,
        // private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
    //    private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public globalsService: GlobalsService,
        private translateService: TranslateService,
        private router: Router,
        public languageService: LanguageService,
        private keycloakService: KeycloakService 
    ) {
        this.selectedLanugage = this.languageService.selectedLanguage;
        this.availableLanguages = this.languageService.availableLanguages;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


          

        console.log('In Classy Component');
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        this.globalsService.languageChangeSubject
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((roleChanged: string) => {

                // this.selectedLanugage = roleChanged;
                this.changeLanguage();
            });

        this.globalsService.userRoles
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((role: UserRole) => {
                console.log(role);
                GlobalsService.userRole = role;

                if (
                    role.organizationType == OrganizationType.BRAND &&
                    role.chemIq === ''
                ) {
                    this.selectedNavigation = this.navigation.brand;
                } else if (role.organizationType == OrganizationType.SUPPLIER) {
                    this.isFactory= true;
                    this.selectedNavigation = this.navigation.supplier;
                } else if (
                    role.organizationType == OrganizationType.BHIVE_ADMIN
                ) {
                    this.selectedNavigation = this.navigation.admin;
                } else if (
                    role.organizationType == OrganizationType.BRAND &&
                    role.chemIq === 'mts'
                ) {
                    console.log(role);
                    this.selectedNavigation = this.navigation.mts;
                } else if (
                    role.organizationType == OrganizationType.BRAND &&
                    role.chemIq === 'brand'
                ) {
                    console.log(role);
                    this.selectedNavigation = this.navigation.brand;
                } else if (
                    role.organizationType == OrganizationType.IMPORTER

                ) {
                    console.log(role);
                    this.selectedNavigation = this.navigation.importer;
                }
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

       

    }



    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(void 0);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openFAQ() {
        window.open(this.globalsService.faqUrl);
    }

    changeLanguage(): void {
        this.translateService.use(
            this.languageService.availableLanguages.find(
                (language) =>
                    language['id'] === this.languageService.selectedLanguage
            )['languageCode']
        );
        this._updateNavigation(this.selectedLanugage);
        //console.log(  this.translateService.use(this.languageService.availableLanguages.find(language => language['id'] === this.languageService.selectedLanguage)['languageCode']));
    }

    showDashboard() {
        this.router.navigateByUrl('/dashboard');
    }

    openUserGuide() {
        const url = 'https://www.thebhive.net/user-guide-languages';
        window.open(url, '_blank');
    }
    // Settings menu methods
    
    showPoliciesData() {
        this.router.navigateByUrl('/policy-data');
    }

    showSettings() {
        this.router.navigateByUrl('/profile');
    }

    logout(): void {
        this.globalsService.isLoggedIn = false;
        sessionStorage.clear();
        this.keycloakService.logout(window.location.origin + '/sign-in');
    }

    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // // Get the Project dashboard item and update its title
        // const userNavItem = this._fuseNavigationService.getItem(
        //     'User',
        //     navigation
        // );
        // if (userNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.USER_GUIDE')
        //         .subscribe((res) => {
        //             userNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }


        const allNav: FuseNavigationItem[] = this._fuseNavigationService.getFlatNavigation(
            navigation
        );

        

        allNav.forEach(nv=>{
            
            this.addMenutranslate(navigation, navComponent, nv.id, 'APP_COMPONENT.'+nv.id);
        })
       
        this.addMenutranslate(navigation, navComponent, 'DATA_INPUT', 'APP_COMPONENT.DATA_INPUT');
        this.addMenutranslate(navigation, navComponent, 'ANALYTICS_AND_REPORT', 'APP_COMPONENT.ANALYTICS_AND_REPORT');
        this.addMenutranslate(navigation, navComponent, 'PROFILE', 'APP_COMPONENT.PROFILE');
        this.addMenutranslate(navigation, navComponent, 'MY_FACILITY', 'APP_COMPONENT.MY_FACILITY');
        this.addMenutranslate(navigation, navComponent, 'MY_INVENTORIES', 'APP_COMPONENT.MY_INVENTORIES');
        this.addMenutranslate(navigation, navComponent, 'EXTERNAL_REPORTS', 'APP_COMPONENT.EXTERNAL_REPORTS');
      


        
        // const mySuplierNavItem = this._fuseNavigationService.getItem(
        //     'My Suppliers',
        //     navigation
        // );
        // if (mySuplierNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.MY_SUPPLIERS')
        //         .subscribe((res) => {
        //             mySuplierNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }


        // const overviewNavItem = this._fuseNavigationService.getItem(
        //     'Overview',
        //     navigation
        // );
        // if (overviewNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.OVERVIEW')
        //         .subscribe((res) => {
        //             overviewNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }



        // this.addMenutranslate(navigation, navComponent, 'Reports', 'APP_COMPONENT.REPORTS');
        // this.addMenutranslate(navigation, navComponent, 'Vendors/Importers', 'APP_COMPONENT.VENDOR_IMPORTER');
        // this.addMenutranslate(navigation, navComponent, 'mychemstore', 'APP_COMPONENT.MY_CHEM_STORE');
        // this.addMenutranslate(navigation, navComponent, 'CAP', 'APP_COMPONENT.CAP');
        // this.addMenutranslate(navigation, navComponent, 'ENVIRONMENTAL_DATA', 'APP_COMPONENT.ENVIRONMENTAL_DATA');
        // this.addMenutranslate(navigation, navComponent, 'INVENTORIES', 'APP_COMPONENT.INVENTORIES');
        // this.addMenutranslate(navigation, navComponent, 'MY_INVENTORIES', 'APP_COMPONENT.MY_INVENTORIES');

        // this.addMenutranslate(navigation, navComponent, 'TRANSFER', 'APP_COMPONENT.TRANSFER');
        // this.addMenutranslate(navigation, navComponent, 'INCHECK', 'APP_COMPONENT.INCHECK');

        // this.addMenutranslate(navigation, navComponent, 'WATER', 'APP_COMPONENT.WATER');
        // this.addMenutranslate(navigation, navComponent, 'ENERGY', 'APP_COMPONENT.ENERGY');
        // this.addMenutranslate(navigation, navComponent, 'CHEMICAL_DATA', 'APP_COMPONENT.CHEMICAL_DATA');
        // this.addMenutranslate(navigation, navComponent, 'HEALTH_AND_SAFTY', 'APP_COMPONENT.HEALTH_AND_SAFTY');








        // Get the Project dashboard item and update its title
        // const chemicalDataNavItem = this._fuseNavigationService.getItem(
        //     'Chemical Data',
        //     navigation
        // );
        // if (chemicalDataNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.CHEMICAL_DATA')
        //         .subscribe((res) => {
        //             chemicalDataNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }

        // const kontoorUserGuideNavItem = this._fuseNavigationService.getItem(
        //     'Kontoor User Guide',
        //     navigation
        // );

        // if (kontoorUserGuideNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.KONTOOR_USER_GUIDE')
        //         .subscribe((res) => {
        //             kontoorUserGuideNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const transInvNavItem = this._fuseNavigationService.getItem(
        //     'Transfer Inventory',
        //     navigation
        // );
        // if (transInvNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.DATA_INPUT_BUTTON')
        //         .subscribe((res) => {
        //             transInvNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const invReportsNavItem = this._fuseNavigationService.getItem(
        //     'Inventory Reports',
        //     navigation
        // );
        // if (invReportsNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.CHEMICAL_INVENTORY_REPORTS_BUTTON')
        //         .subscribe((res) => {
        //             invReportsNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }

        // const supportNavItem = this._fuseNavigationService.getItem(
        //     'Support',
        //     navigation
        // );
        // if (supportNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUPPORT_MAIL_TO')
        //         .subscribe((res) => {
        //             supportNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }

        // const dataAndDocNavItem = this._fuseNavigationService.getItem(
        //     'Data & Documents',
        //     navigation
        // );
        // if (dataAndDocNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.MANAGE_DOCUMENTS_BUTTON')
        //         .subscribe((res) => {
        //             dataAndDocNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const analyticsNavItem = this._fuseNavigationService.getItem(
        //     'Analytics',
        //     navigation
        // );
        // if (analyticsNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.ANALYTICS_BUTTON')
        //         .subscribe((res) => {
        //             analyticsNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const profileNavItem = this._fuseNavigationService.getItem(
        //     'Profile',
        //     navigation
        // );
        // if (profileNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.MY_PROFILE_BUTTON')
        //         .subscribe((res) => {
        //             profileNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const substitutionItem = this._fuseNavigationService.getItem(
        //     'Substitution',
        //     navigation
        // );
        // if (substitutionItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUBSTITUTION_BUTTON')
        //         .subscribe((res) => {
        //             substitutionItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const invOrderNavItem = this._fuseNavigationService.getItem(
        //     'Inventory Order',
        //     navigation
        // );
        // if (invOrderNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.INVENTORY_ORDER_BUTTON')
        //         .subscribe((res) => {
        //             invOrderNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }



        // const myProfileNavItem = this._fuseNavigationService.getItem(
        //     'My Profile',
        //     navigation
        // );
        // if (myProfileNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.MY_PROFILE_BUTTON')
        //         .subscribe((res) => {
        //             myProfileNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const higgNavItem = this._fuseNavigationService.getItem(
        //     'Higg FEM 3.0 Data',
        //     navigation
        // );
        // if (higgNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.HIGG_FEM_3_0_DATA_BUTTON')
        //         .subscribe((res) => {
        //             higgNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const extReportsNavItem = this._fuseNavigationService.getItem(
        //     'External Reports',
        //     navigation
        // );
        // if (extReportsNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.EXTERNAL_REPORTS')
        //         .subscribe((res) => {
        //             extReportsNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const extOtherReportsNavItem = this._fuseNavigationService.getItem(
        //     'Others Reports',
        //     navigation
        // );
        // if (extOtherReportsNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.OTHERS')
        //         .subscribe((res) => {
        //             extOtherReportsNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const incheckReportsNavItem = this._fuseNavigationService.getItem(
        //     'Performance InCheck',
        //     navigation
        // );
        // if (incheckReportsNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.PERFORMANCE_INCHECK')
        //         .subscribe((res) => {
        //             incheckReportsNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const suppConnNavItem = this._fuseNavigationService.getItem(
        //     'Supplier Connections',
        //     navigation
        // );
        // if (suppConnNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUPPLIER_CONN_BUTTON')
        //         .subscribe((res) => {
        //             suppConnNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const suppChemIqNavItem = this._fuseNavigationService.getItem(
        //     'Supplier ChemIQ',
        //     navigation
        // );
        // if (suppChemIqNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUPPLIER_CHEMIQ')
        //         .subscribe((res) => {
        //             suppChemIqNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const suppInvNavItem = this._fuseNavigationService.getItem(
        //     'Supplier Inventories',
        //     navigation
        // );
        // if (suppInvNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUPPLIER_INVENTORIES_BUTTON')
        //         .subscribe((res) => {
        //             suppInvNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const suppNavItem = this._fuseNavigationService.getItem(
        //     'Suppliers',
        //     navigation
        // );
        // if (suppNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.WPU_BUTTON')
        //         .subscribe((res) => {
        //             suppNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const chemIqNavItem = this._fuseNavigationService.getItem(
        //     'ChemIQ',
        //     navigation
        // );
        // if (chemIqNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.CHEMIQ')
        //         .subscribe((res) => {
        //             chemIqNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const mtsChemIqNavItem = this._fuseNavigationService.getItem(
        //     'MTS ChemIQ',
        //     navigation
        // );
        // if (mtsChemIqNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SUPPLIER_CHEMIQ')
        //         .subscribe((res) => {
        //             mtsChemIqNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const adminPanelNavItem = this._fuseNavigationService.getItem(
        //     'Admin Panel',
        //     navigation
        // );
        // if (adminPanelNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.ADMIN_PANEL_BUTTON')
        //         .subscribe((res) => {
        //             adminPanelNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const incheckSubNavItem = this._fuseNavigationService.getItem(
        //     'InCheck Subscriptions',
        //     navigation
        // );
        // if (incheckSubNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.INCHECK_SUBSCRIPTIONS_BUTTON')
        //         .subscribe((res) => {
        //             incheckSubNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }

        // const referralLinkNavItem = this._fuseNavigationService.getItem(
        //     'Referral Links',
        //     navigation
        // );
        // if (referralLinkNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.REFERRAL_LINKS_BUTTON')
        //         .subscribe((res) => {
        //             referralLinkNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const userManagNavItem = this._fuseNavigationService.getItem(
        //     'User Management',
        //     navigation
        // );
        // if (userManagNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.USER_MANAGEMENT_BUTTON')
        //         .subscribe((res) => {
        //             userManagNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const userOnBoardingNavItem = this._fuseNavigationService.getItem(
        //     'User Onboarding',
        //     navigation
        // );
        // if (userOnBoardingNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.USER_ONBOARDING_BUTTON')
        //         .subscribe((res) => {
        //             userOnBoardingNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const chemIqStatusNavItem = this._fuseNavigationService.getItem(
        //     'ChemIQ Status',
        //     navigation
        // );
        // if (chemIqStatusNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.CHEMIQ_STATUS')
        //         .subscribe((res) => {
        //             chemIqStatusNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }
        // const sharedConnNavItem = this._fuseNavigationService.getItem(
        //     'Shared Connections',
        //     navigation
        // );
        // if (sharedConnNavItem) {
        //     this.translateService
        //         .get('APP_COMPONENT.SHARED_CONN_BUTTON')
        //         .subscribe((res) => {
        //             sharedConnNavItem.title = res;
        //             navComponent.refresh();
        //         });
        // }

        navComponent.refresh();
    }


    addMenutranslate(navigation, navComponent, labelId, enJsonPropertyName) {
        let navItem = this._fuseNavigationService.getItem(
            labelId,
            navigation
        );
        if (navItem) {
            this.translateService
                .get(enJsonPropertyName)
                .subscribe((res) => {
                    navItem.title = res;
                    navComponent.refresh();
                });
        }

    }
}
