<div class="container">
 <mat-toolbar class="h-10 mt-1">
        <div class="dashboard-buttons">
            <button class=" text-sm rounded-none mat-button-base mat-flat-button"
                (click)="closeFilterMenu()">
        <mat-icon class="text-sm" svgIcon="mat_outline:menu_open"></mat-icon>
            </button>
            <!-- <button *ngIf="!isEditing" [matMenuTriggerFor]="downloadMenu"
        class="px-6 text-sm rounded-none mat-button-base mat-flat-button">
        <mat-icon class="text-sm" svgIcon="mat_outline:insert_drive_file"></mat-icon>File
            </button> -->
            <!-- <mat-menu #downloadMenu="matMenu" id="more_menu">
                <button mat-menu-item (click)="downloadReport()">
                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                    <span>Save as PDF</span>
      </button>
                <button mat-menu-item (click)="exportRawData()">
                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                    <span>Save as Excel</span>
                </button>
            </mat-menu> -->
            <button *ngIf="!isEditing" class="text-sm rounded-none mat-button-base mat-flat-button"
        (click)="initializeDashboardEditing()">
        <mat-icon class="text-sm" svgIcon="mat_outline:edit"></mat-icon>Edit
      </button>
      <button *ngIf="isEditing" class="text-sm rounded-none mat-button-base mat-flat-button"
        (click)="closeDashboard()">
        <mat-icon class="text-sm" svgIcon="mat_outline:close"></mat-icon> Close
      </button>
      <button *ngIf="isEditing" class="text-sm rounded-none mat-button-base mat-flat-button"
        (click)="openSaveDialog()">
        <mat-icon class="text-sm" svgIcon="mat_outline:done"></mat-icon>Save
      </button>
      <button *ngIf="!isEditing" class="text-sm rounded-none mat-button-base mat-flat-button"
        (click)="initializeNewDashboard()">
        <mat-icon class="text-sm"
          style="color: color(srgb 0.3512 0.7722 0.8925)!important;"
          svgIcon="mat_outline:add_circle_outline"></mat-icon>New
      </button> 
    </div>
</mat-toolbar>
    <mat-drawer-container #one class="flex w-full h-full" [hasBackdrop]="false">
        <mat-drawer #drawer class="space-between mr-5 py-4 space-y-7 px-8" mode="push" style="width: 23%;">
            <div class=" relative" style="width: 100%; height: 100%; overflow: hidden;">
            <mat-form-field class="block" appearance="fill">
                <mat-select placeholder="Queries" [formControl]="queryControl">
                    <input matInput type="text" class="ml-4 mat-label inputinputSearchFieldField" placeholder="Search" [(ngModel)]="searchQuery" (keydown.space)="$event.stopPropagation()">
                    <ng-container *ngFor="let group of groupedQueries | filter: searchQuery">
                        <mat-optgroup [label]="group.name">
                            <mat-option *ngFor="let query of group.queries"  (onSelectionChange)="onQuerySelection($event)" [value]="query"  matTooltip="{{ query.Name }}" matTooltipClass="queriesTooltip">{{ query.Name }}</mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
            </mat-form-field>


                <!-- <mat-divider></mat-divider> -->
                <mat-form-field class="block" appearance="fill">
                    <!-- <mat-label>Choose Filters</mat-label> -->
                    <mat-select placeholder="Choose Filters" [formControl]="filterControl" multiple>
                        <input matInput type="text" class ="ml-4 mat-label inputinputSearchFieldField" placeholder="Search" [(ngModel)]="searchFilter">
                        <mat-option *ngFor="let filter of filteredFilters | filter: searchFilter"
                            (onSelectionChange)="onFilterSelection($event)" [value]="filter">{{filter.ShowName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-divider></mat-divider> -->
                <mat-button-toggle-group name="visualType" [formControl]="visualControl" #group="matButtonToggleGroup"
                    (change)="showPreview()" class="grid gap-4 grid-cols-3" aria-label="Selected visual">
                    <mat-button-toggle class="btn-visual" *ngFor="let visualType of configs['visuals']" title="{{visualType.Name}}" [value]="visualType.Id" [disabled]="!validVisualCodes.includes(visualType.Code)">
                        <!-- <mat-icon svgIcon="mat_outline:{{visualType.Name}}"></mat-icon> -->
                        <mat-icon *ngIf="visualType.Name != 'grouped_bar_chart'" class="material_icons_outline" >{{visualType.Name}}</mat-icon>
                        <mat-icon *ngIf="visualType.Name == 'grouped_bar_chart'" svgIcon="grouped_bar_chart"></mat-icon>
                    </mat-button-toggle>
                <!--      <mat-button-toggle value="line">
                    <mat-icon fontIcon="123"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="pie">
                    <mat-icon svgIcon="mat_outline:pie_chart"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table">
                    <mat-icon svgIcon="mat_outline:table_chart"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="grouped"><span class="material-symbols-outlined">grouped_bar_chart</span>
                </mat-button-toggle>
                <mat-button-toggle value="multiline">
                    <mat-icon svgIcon="mat_outline:multiline_chart"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="stacked">
                    <mat-icon svgIcon="mat_outline:stacked_bar_chart"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="bubble">
                    <mat-icon svgIcon="mat_outline:bubble_chart"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="dounut">
                    <mat-icon svgIcon="mat_outline:donut_large"></mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="area"><span class="material-symbols-outlined">
                        area_chart
                    </span></mat-button-toggle> -->
                </mat-button-toggle-group>
                <div id="visual_desc_container" class="h-40 mt-8">
                    <label>Description</label>
                    <p id="visual_desc" [innerHTML]="queryDescription"></p>
                    <div class="mt-20">
                        <p *ngIf="previewQueryPath != ''"><i>Here is an example how it looks like:</i></p>
                        <img src={{previewQueryPath}}>
                    </div>
                </div>
                <div id="save_button" class=" flex absolute justify-center bottom-0 w-full space-x-4">
                    <!-- <button class="px-6 mat-button-base mat-flat-button" (click)="onClose()">Close</button> -->
                    <button class="px-6 mat-button-base mat-primary mat-flat-button" (click)="addItem()">Add</button>
                </div>
            </div>
        </mat-drawer>

        <mat-drawer-content class="space-y-7 " [ngStyle]='{ width : width+"%" }'>
            <mat-drawer-container #two class="w-full  h-full" [hasBackdrop]="false">
                <mat-drawer #filterDrawer opened mode="push" class="space-between mr-5 py-4 space-y-7 px-8" style="width: 23%;">
                    <mat-form-field appearance="fill" class="block">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [formControl]="startDate">
                            <input matEndDate placeholder="End date" [formControl]="endDate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <div *ngFor="let filt of chosenMenuFilters; let index = index" class="flex flex-row" style="align-items:center;">
                        <button *ngIf="isEditing" class="h-full" (click)="removeFilter($event, index)" style="margin-right:3px;"><mat-icon
                                class="text-sm self-center" svgIcon="mat_outline:delete"></mat-icon></button>
                        <mat-form-field *ngIf="filt['Type'] === 'multiple' && filt['ShowName'] !== 'Standards'" class="block" [ngStyle]="{'width': (isEditing) ? '92%' : '100%'}"
                            appearance="fill">
                            <mat-select placeholder="Choose {{filt['ShowName']}}"
                                [formControl]="filterMenuControls[filt['ShowName']]" multiple (openedChange)="onOpenChange(filt['ShowName'])">
                                <input
                                #dynamicInput
                                type="text"
                                class="ml-4 inputField"
                                autocomplete="off"
                                matInput
                                placeholder="Search By {{filt['ShowName']}}"
                                (input)="onInputChange($event, filt['ShowName'])"
                                />
                                <mat-option *ngFor="let option of itemFilter[filt['ShowName']]" [value]="option[filt['ShowName']]"   (onSelectionChange)="selection($event, filt['ShowName'])">
                                    {{option[filt['ShowName']]}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="filt['Type'] === 'single' && filt['ShowName'] !== 'Standards'" class="block" [ngStyle]="{'width': (isEditing) ? '92%' : '100%'}"
                            appearance="fill">
                            <mat-select placeholder="Choose {{filt['ShowName']}}"
                                [formControl]="filterMenuControls[filt['ShowName']]">
                                <mat-option *ngFor="let option of filt.Data" [value]="option[filt['ShowName']]">
                                    {{option[filt['ShowName']]}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="filt['ShowName'] === 'Standards'" class="block" [ngStyle]="{'width': (isEditing) ? '92%' : '100%'}"
                            appearance="fill">
                            <mat-select placeholder="Choose {{filt['ShowName']}}"
                                [formControl]="filterMenuControls[filt['ShowName']]">
                                <mat-option *ngFor="let option of filt.Data" [value]="option">
                                    {{option['displayName']}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="filt['Type'] === 'input' && filt['ShowName'] !== 'Standards'" class="block" [ngStyle]="{'width': (isEditing) ? '92%' : '100%'}">
                            <input matInput type="text" placeholder="Choose {{filt['ShowName']}}" [formControl]="filterMenuControls[filt['ShowName']]"/>
                        </mat-form-field>
                        <div class="ml-2" *ngIf="filt['Type'] === 'toggle' && filt['ShowName'] !== 'Standards'" [ngStyle]="{'width': (isEditing) ? '92%' : '100%'}">
                        <mat-label class="mr-3"><b>Brand Scope</b></mat-label>
                        <mat-slide-toggle color="primary" [formControl]="filterMenuControls[filt['ShowName']]"></mat-slide-toggle>
                        </div>
                    </div>

                    <!-- <div id="save_button" class=" flex  justify-center "> -->
                        <!-- <button class="px-6 mat-button-base mat-flat-button" (click)="onClose()">Close</button> -->
                        <!-- <button matTooltip="Apply Filters"  (click)="applyFilters()" style="margin-right:25px;">Apply<mat-icon  svgIcon="filter"></mat-icon></button>
                         <button matTooltip="Reset Filters"  (click)="resetFilters()">Reset<mat-icon  svgIcon="reset"></mat-icon></button>
                    </div> -->
                        <div class="mb-3" style="display: flex; justify-content: space-between;">
                            <button
                                mat-button
                            (click)="applyFilters()"
                            >
                                <mat-icon svgIcon="filter"></mat-icon>Apply
                            </button>
                            <button mat-button (click)="resetFilters()">
                                <mat-icon svgIcon="reset"></mat-icon>Reset                            
                            </button>
                    </div>
                </mat-drawer>
                <mat-drawer-content class="h-full" [ngStyle]='{ width : width2+"%" }'>
                    <gridster [options]="options" id="dash" #dashElement>
                        <gridster-item [item]="item.gridItem" *ngFor="let item of dashboardItems">
                            <div name="dash_item" id="{{item.data.Id}}_{{item.visualCode}}">
                                <div>
                                    <div class="chart_title" [ngStyle]="{ 'font-size': calculateFontSize(item.width,item.height ) }">{{item.data.Name}}</div>
                                    <button id="more_button" [matMenuTriggerFor]="itemDownloadMenu" [ngStyle]="{ 'font-size': calculateFontSize(item.width,item.height ) }"
                                        >
                                        <mat-icon class="icon text-sm" svgIcon="mat_outline:more_vert"></mat-icon>
                                    </button>
                                    <mat-menu class="w-1/4" #itemDownloadMenu="matMenu" id="more_menu">
                                        <button mat-menu-item color="warn" *ngIf="isEditing" (click)="removeItem(item)">
                                            <mat-icon svgIcon="mat_outline:remove"></mat-icon>
                                            <span>Remove chart</span>
                                        </button>
                                        <button mat-menu-item *ngIf="item.groupings.length > 0" [matMenuTriggerFor]="groupingSubMenu">Group by</button>
                                        <button mat-menu-item *ngIf="item.data.HasTarget"  [matMenuTriggerFor]="targetSubMenu">Set target</button>
                                        <button mat-menu-item *ngIf="item.data.CanCompare" (click)="openCompareDialog(item)">Compare</button>
                                        <button mat-menu-item (click)="downloadChart(item.data)">
                                            <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                            <span>Save as Excel</span>
                                        </button>
                                    </mat-menu>
                                    <mat-menu #groupingSubMenu="matMenu">
                                        <button mat-menu-item *ngFor="let group of item.groupings" (click)="groupBy(item, group['value'])">
                                            <span *ngIf="item.selectedGroups.includes(group.value)" class="tick-icon">&#10003;</span>
                                            {{group['name']}}
                                        </button>
                                    </mat-menu>
                                    <mat-menu class="w-1/4 chart-target" #targetSubMenu="matMenu">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Target</mat-label>
                                            <input matInput type="number" placeholder="Ex. 50" [formControl]="target" (click)="$event.stopPropagation()">
                                        </mat-form-field>
                                        <div class="chart-target-Button">
                                        <button color="primary" mat-raised-button (click)="setTarget(item)" class="table-filter-select-buttons-Search">Apply</button>
                                        </div>
                                    </mat-menu>
                                </div>
                                <mat-divider></mat-divider>

                                <bhive-dashboard-item [width]="item.width" [height]="item.height" [data]="item.data"
                                    [visualCode]="item.visualCode" [filters]="item.filters" [fullItem]="item" [organizationId]="organizationid">
                                </bhive-dashboard-item>
                            </div>
                        </gridster-item>
                    </gridster>
                </mat-drawer-content>
            </mat-drawer-container>

        </mat-drawer-content>

    </mat-drawer-container>
</div>
