export const tableHeadersTranslations = {
    "Formulas.year_month_scan_date": 'Inventory Month',
    "Formulas.scan_date": 'Scan Date',
    "Formulas.total_count": 'Chemical Products (Total)',
    "Formulas.total_consumption": 'Consumption (kg)',
    "Formulas.factory_compliance_count": 'Chemical Products (Unique)',
    "Formulas.factory_unverified_pct": 'Non Compliant by Count (%)',
    "Formulas.factory_distinct_count": 'Factory Count',
    "Formulas.brand_compliance_consumption": 'Compliant Consumption (kg)',
    "Formulas.brand_compliance_count": 'Compliant Count (kg)',
    "Formulas.factory_compliant_pct": 'Compliance by Count (%)',
    "Formulas.brand_compliant_pct": 'Compliance by Count (%)',
    "Formulas.brand_compliant_consumption_pct": 'Compliance by Counsumption (%)',
    "Formulas.brand_unverified_consumption_pct": 'Non Compliant by Counsumption (%)',
    "Formulas.brand_unverified_count": 'Non Compliant by Count (kg)',
    "Formulas.organization_name": 'Organization Name',
    "Formulas.chemical_name": 'Chemical Name',
    "Formulas.chemical_manufacturer": 'Chemical Manufacturer',
    "Formulas.gateway_level": 'Gateway Level',
    "Formulas.in_cap": 'In Cap',
    "Formulas.h_phrases_count": 'H Phrases Count',
    "Formulas.p_phrases_count": 'P Phrases Count',
    "Formulas.cas_nr": 'Cas Nr',
    "Formulas.country": 'Country',
    "Factories.country_name": 'Country Name',
    "Factories.factory_name": 'Factory Name',
    "InCheckData.last_generated": 'Last generated incheck',
    "Higg.higg_last_upload_date": 'Upload date',
    "WasteWaterAll.latest_test_report": 'WasteWater Latest Test Report',
    "WasteWaterAll.test_result": 'WasteWater Test Report',
    "WasteWaterAll.total_count": 'WasteWater Test Reports',
    "WasteWaterAll.document_date": 'Document Date',
    "WasteWaterAll.sum_parameter": 'Sum Parameter',
    "WasteWaterAll.anions": 'Anions',
    "WasteWaterAll.metals": 'Metals',
    "WasteWaterAll.zdhc_mrsl": 'ZDHS MRSL',
    "Higg.self_total": 'Self-Reported Assessment Results',
    "Higg.verf_total": 'Verfied Assessment Results',
    "Formulas.zdhc_mrsl": 'zdhc_mrsl',
    "Formulas._type": 'Source',
    "Formulas.basic_chemical": 'Basic Chemical',
    "Formulas.count_factories_using": 'Nr of factories using this product',
    "Formulas.factory_usage": 'General Usage',
    "BrandSuppliers.country_name": 'Factory Country Name',
    "BrandSuppliers.connected_count": 'Connected Factories',
    "BrandSuppliers.nominated_count": 'Nominated Factories',
    "BrandActivityWithCompliance.facility_id": "Facility ID",
    "BrandActivityWithCompliance.supplier_name": "Factory Name",
    "BrandActivityWithCompliance.supplier_id": "Bhive ID",
    "BrandActivityWithCompliance.last_scan_date": "Last Scan Date",
    "BrandActivityWithCompliance.brand_scope": "Otto Scope",
    "BrandActivityWithCompliance.country": "Country",
    "BrandActivityWithCompliance.year": "Year",
    "BrandActivityWithCompliance.active": "Active",
    "BrandActivityWithCompliance.connected": "Connected",
    "BrandActivityWithCompliance.q1": "Q1",
    "BrandActivityWithCompliance.q2": "Q2",
    "BrandActivityWithCompliance.q3": "Q3",
    "BrandActivityWithCompliance.q4": "Q4",
    "BrandActivityWithCompliance.q4_count": "CIL uploaded Q4",
    "BrandActivityWithCompliance.q1_count": "CIL uploaded Q1",
    "BrandActivityWithCompliance.q2_count": "CIL uploaded Q2",
    "BrandActivityWithCompliance.q3_count": "CIL uploaded Q3",
    "BrandActivityWithCompliance.nominated_by_vendor": "Nominated by OGC",
    "Formulas.incheck_level1_compliant_cnt": "Compliant Incheck Level 1",
    "Formulas.incheck_level2_compliant_cnt": "Compliant Incheck Level 2",
    "Formulas.incheck_level3_compliant_cnt": "Compliant Incheck Level 3",
    "Formulas.incheck_registered_compliant_cnt": "Compliant Incheck Registered",
    "Formulas.incheck_not_evaluated_cnt": "Not Evaluated",
    "Formulas.incheck_expired_cnt": "Incheck Expired",
    "BrandSuppliers.organization_name": "Factory Name",
    "BrandSuppliers.created_date": "Created Date",
    "Activity.organization_name": "Factory Name",
    "Activity.country_name": "Country Name",
    "Activity.scan_date": "Scan Date",
    "WasteWater.test_result": "Test Result",
    "InCheckData.organization_name": "Factory Name",
    "InCheckData.manufacturer": "Manufacturer",
    "InCheckData.start_date": "Start Date",
    "Activity.supplier_identification": "Supplier Id",
    "Activity.zdhc_aid": "ZDHC Aid",
    "Activity.in_out_scope": "In/Out Scope",
    "Activity.email": "Email",
    "Activity.organization_type": "Organization Type",
    "BrandSuppliers.connection_type": "Connection Type",
    "Activity.last_scan_date": "Last Scan Date",
    "Activity.confirmation_of_completeness": "Confirmation of Completeness",
    "Higg.higg_factory_count": "Factories that uploaded Higg FEM results",
    "WasteWaterAll.pass_total_count": "Pass",
    "WasteWaterAll.failed_total_count": "Fail",
    "WasteWaterAll.na_total_count": "Not applicable",
    "WasteWaterAll.no_info_total_count": "No Info",
    "Formulas.database_formula_count": "Scanned Chemicals",
    "Formulas.manformula_formula_count": "Manually Added Chemicals",




    //TODO: Add all cubejs measures/dimensions conversions
};


export const FilterKeys = {
    "Date": 'date',
    "Country": 'country',
    "Fabric Roll ID": 'fabricRollId',
    "Organization": 'organization',
    "Vendor ID": 'vendorID',
    "Vendor Name": 'vendorPartner',
    "Factory Usage": 'factoriesUsage',
    "Product Category": 'productCategory',
    "Chemical Name": 'chemicalName',
    "Chemical Manufacturer": 'chemicalManufacturer',
    "Vendor Type": 'vendorType',
    "Department": 'department',
    "Standards": 'standards',
    "Gateway Version": 'gatewayVersion',
    "Gateway Level": 'gatewayLevel',
    "In/Out of Scope": 'identification_supplier_id',
    "Used For Customer": 'usedFor'
    // TODO: Add more filter keys
};

export const SeriesColorMap: { [key: string]: Array<string> } = {
    "Formulas.factory_compliance_count": ['#5E6D91',
        '#6e7b9c',
        '#7e8aa7',
        '#8e98b2',
        '#9ea7bd',
        '#aeb6c8',
        '#bec4d3',
        '#ced3de',
        '#dee1e9',
        '#eef0f4'],
    "Formulas.brand_compliance_consumption": ['#EBDCD5',
        '#d3c6bf',
        '#bcb0aa',
        '#a49a95',
        '#8d847f',
        '#756e6a',
        '#5e5855',
        ' #46423f',
        '#2f2c2a',
        '#171615'
    ],
    "Formulas.factory_compliant_pct": ['#55b4cd',
        '#66bbd2',
        '#76c3d7',
        '#88cadc',
        '#99d2e1',
        '#aad9e6',
        '#bbe1eb',
        '#cce8f0',
        '#ddf0f5'],
    "Compliance by Count (%)": ['#55b4cd',
        ' #4ca0b6',
        '#428c9f',
        ' #397888',
        '#2f6472',
        '#26505b',
        '#1c3c44',
        '#13282d',
        '#091416'],
    "Formulas.brand_compliant_pct": ['#5FC8E4', '#55b4cd',
        '#66bbd2',
        '#76c3d7',
        '#88cadc',
        '#99d2e1',
        '#aad9e6',
        '#bbe1eb',
        '#cce8f0',
        '#ddf0f5'],
    "Formulas.factory_unverified_pct": ['#3b7d8f',
        '#357080',
        '#2f6472',
        '#295764',
        '#234b55',
        '#1d3e47',
        '#173239'],
    "Non Compliant by Count (%)": ['#3b7d8f',
        '#357080',
        '#2f6472',
        '#26505b',
        '#1c3c44',
        '#13282d',
        '#091416'],
    "Formulas.brand_compliant_consumption_pct": ['#6277ba',
        '#7184c0',
        '#8192c7',
        '#919fce',
        '#a0add5',
        '#b0bbdc',
        '#c0c8e3'
    ],
    "Brand Compliant Counsumption Pct": ['#6277ba',
        '#7184c0',
        '#8192c7',
        '#919fce',
        '#a0add5',
        '#b0bbdc',
        '#c0c8e3'
    ],
    "Formulas.brand_unverified_consumption_pct": ['#1d2847',
        '#333d59',
        '#4a526b',
        '#60687e',
        '#777e90',
        '#8e93a3',
        '#a4a9b5'
    ],
    "Non Compliant by Counsumption (%)": ['#1d2847',
        '#333d59',
        '#4a526b',
        '#60687e',
        '#777e90',
        '#8e93a3',
        '#a4a9b5'
    ],
    "Formulas.factory_unverified_count": ['#F3F3F5', '#dadadc',
        '#c2c2c4',
        '#aaaaab',
        '#919193',
        '#79797a',
        '#616162',
        '#484849',
        '#303031',
        '#181818'],
    "Formulas.total_count": ['#0475ED',
        '#0251a5',
        '#68acf4',
        '#02468e',
        '#4f9ef2',
        '#023a76',
        '#81baf6'],
    "Formulas.brand_unverified_consumption": ['#1F9CAF',
        '#1b8c9d',
        '#187c8c',
        '#156d7a',
        '#125d69',
        '#0f4e57',
        '#0c3e46',
        '#092e34',
        '#061f23'],
    "Formulas.brand_unverified_count": ['#F3F3F5', '#dadadc',
        '#c2c2c4',
        '#aaaaab',
        '#919193',
        '#79797a',
        '#616162',
        '#484849',
        '#303031',
        '#181818'],
    "Formulas.brand_compliance_count": ['#5E6D91',
        '#6e7b9c',
        '#7e8aa7',
        '#8e98b2',
        '#9ea7bd',
        '#aeb6c8',
        '#bec4d3',
        '#ced3de',
        '#dee1e9',
        '#eef0f4'],
    "Formulas.database_formula_count": ['#916B7D',
        '#604653',
        '#7e8aa7',
        '#8e98b2',
        '#9ea7bd',
        '#aeb6c8',
        '#bec4d3',
        '#ced3de',
        '#dee1e9',
        '#eef0f4'],
    "Formulas.manformula_formula_count": ['#726987',
        '#393343',
        '#7e8aa7',
        '#8e98b2',
        '#9ea7bd',
        '#aeb6c8',
        '#bec4d3',
        '#ced3de',
        '#dee1e9',
        '#eef0f4'],
    "Formulas.factory_distinct_count": ['#8d654e',
        '#98745f',
        '#70503e',
        '#a38371',
        '#af9383',
        '#baa294',
        '#624636',
        '#c6b2a6'],
    "Formulas.incheck_level1_compliant_cnt": ['#9ea7bd',
        '#8e96aa', '#7e8597', '#6e7484', '#5e6471', '#4f535e', '#3f424b', '#2f3238', '#1f2125', '#0f1012',],
    "Formulas.incheck_level2_compliant_cnt": ['#bd9ea7',
        '#aa8e96', '#977e85', '#846e74', '#715e64', '#5e4f53', '#4b3f42', '#382f32', '#251f21'],
    "Formulas.incheck_level3_compliant_cnt": ['#a7bd9e',
        '#96aa8e', '#85977e', '#74846e', '#64715e', '#535e4f', '#424b3f', '#32382f'],
    "Formulas.incheck_registered_compliant_cnt": ['#bdb49e',
        '#aaa28e',
        '#97907e',
        '#847d6e',
        '#716c5e',
        '#5e5a4f',
        '#4b483f',
        '#38362f'],
        "under 50%": ['#F3F3F5', '#dadadc',
    '#c2c2c4',
    '#aaaaab',],
    "50%-75%": ['#5E6D91',
        '#6e7b9c',
        '#7e8aa7'],
    "over 75%": ['#5FC8E4', '#55b4cd',
    ' #4ca0b6',
    '#428c9f',],
    "Formulas.incheck_not_evaluated_cnt": ['#bda59e',
        '#c3aea7',
        '#cab7b1',
        '#d0c0bb',
        '#d7c9c4',
        '#ded2ce',
        '#e4dbd8',
        '#ebe4e1'
    ],
    "Formulas.incheck_expired_cnt": ['#cfc199',
        '#baad89',
        '#a59a7a',
        '#90876b',
        '#7c735b',
        '#67604c',
        '#524d3d',
        '#3e392d'
    ],

    "Compliant Incheck Level 1": ['#9ea7bd',
        '#8e96aa', '#7e8597', '#6e7484', '#5e6471', '#4f535e', '#3f424b', '#2f3238', '#1f2125', '#0f1012',],
    "Compliant Incheck Level 2": ['#bd9ea7',
        '#aa8e96', '#977e85', '#846e74', '#715e64', '#5e4f53', '#4b3f42', '#382f32', '#251f21'],
    "Compliant Incheck Level 3": ['#a7bd9e',
        '#96aa8e', '#85977e', '#74846e', '#64715e', '#535e4f', '#424b3f', '#32382f'],
    "Compliant Incheck Registered": ['#bdb49e',
        '#aaa28e',
        '#97907e',
        '#847d6e',
        '#716c5e',
        '#5e5a4f',
        '#4b483f',
        '#38362f'],
    "Not Evaluated": ['#bda59e',
        '#c3aea7',
        '#cab7b1',
        '#d0c0bb',
        '#d7c9c4',
        '#ded2ce',
        '#e4dbd8',
        '#ebe4e1'
    ],
    "Incheck Expired": ['#cfc199',
        '#baad89',
        '#a59a7a',
        '#90876b',
        '#7c735b',
        '#67604c',
        '#524d3d',
        '#3e392d'
    ],
    "Pending": ['#be9e8d',
        '#ab8e7e',
        '#987e70',
        '#856e62',
        '#725e54',
        '#5f4f46',
        '#4c3f38',
        '#392f2a'],
    "Resolved": ['#8d654e',
        '#7e5a46',
        '#70503e',
        '#624636',
        '#543c2e',
        '#463227',
        '#38281f',
        '#2a1e17',
    ],
    "Not in Cap": ['#ebdcd5',
        '#d3c6bf',
        '#bcb0aa',
        '#a49a95',
        '#8d847f',
        '#756e6a',
        '#5e5855',
        '#46423f'],
    "BrandSuppliers.connected_count": ["#a9d5e7",
        "#b1d9e9",
        "#baddeb",
        "#c2e1ee",
        "#cbe5f0",
        "#d4eaf3",
        "#dceef5",
        "#e5f2f7"],
    "BrandSuppliers.nominated_count": ["#54aacd",
        "#4b99b8",
        "#4388a4",
        "#3a768f",
        "#32667b",
        "#2a5566",
        "#214452",
        "#19333d",
        "#102229"],
    "WasteWaterAll.pass_total_count": ['#4ca64c',
        '#66b266',
        '#7fbf7f',
        '#99cc99',
        '#b2d8b2',
        '#cce5cc'
    ],
    "WasteWaterAll.failed_total_count": ['#f26767',
        '#f37676',
        '#f48585',
        '#f59494',
        '#f7a3a3',
        '#f8b3b3'],
    "WasteWaterAll.na_total_count": ['#ffe084',
        '#ffe493',
        '#ffe8a3',
        '#ffecb2',
        '#ffefc1'
    ],
    "WasteWaterAll.no_info_total_count": ['#f9cb9c',
        '#e0b68c',
        '#c7a27c',
        '#ae8e6d',
        '#95795d'
    ],
    "Higg.higg_factory_count": ['#a2c4c9',
        '#abc9ce',
        '#b4cfd3',
        '#bdd5d9',
        '#c7dbde',
        '#d0e1e4'
    ],
    "WasteWaterAll.total_count": ['#3d85c6',
        '#5091cb',
        '#639dd1',
        '#77a9d7',
        '#8ab5dc',
        '#9ec2e2'
    ],

    // Add more series names and corresponding colors as needed
};