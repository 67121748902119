import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxLoadingModule } from 'ngx-loading';
import { CommonFileUploadComponent } from './common-file-upload/common-file-upload.component';
import { CommonPopupMessageComponent } from './common-popup-message/common-popup-message.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';

@NgModule({
    imports: [
        CommonModule,
        NgxLoadingModule.forRoot({ fullScreenBackdrop: true }),
        MatIconModule,
        MatDialogModule,
        MatBadgeModule,
        TranslateModule,
        MatButtonModule, 
        MatInputModule
 
    ],

    declarations: [
        CommonPopupMessageComponent,
        CommonFileUploadComponent,
        MatSelectSearchComponent 
        
    ],
    entryComponents: [CommonPopupMessageComponent],
    exports:[
        CommonFileUploadComponent,
        MatSelectSearchComponent
        
    ]


})
export class CommonUsedModule { }
