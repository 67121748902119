import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { GlobalsService } from 'app/globals-services/globals.service';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { fill } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {
  isSupplier: boolean = GlobalsService.userRole.organizationType == OrganizationType.SUPPLIER;

  constructor(private toastService: ToastService) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }

public exportMonthlyReport(json: any[], excelFileName: string): void {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(excelFileName);

  // Customize header based on your conditions
  let headerRow =[];
  if(GlobalsService.isAldi) {
  headerRow = ["Factory Name", "ALDI ID", "Date of inventory", "ALDI Group Number of chemicals", "Consumption", "Total Production Facility", "ALDI Group Production"];
} else if (GlobalsService.isAldiSourcing) {
  headerRow = ["Factory Name", "email", "Country", "Last Scan Date", "Has Uploaded Last Month", "Gateway Subscription", "Connected to ALDI Sourcing", "Connected to ALDI Einkauf SE & Co. oHG", "Connected to Aldi Süd KG", "# of chemicals uploaded for ALDI Sourcing", "# of chemicals uploaded for ALDI Group Production", "Total Chemicals Uploaded"];
} else if (GlobalsService.isAldiImporter) {
  headerRow = ["Factory Name", "Date of Inventory", "Nr. Of Chemicals used for ALDI", "Nr. Of Chemicals for FL Textil", "Total Nr of Chemicals", "% Compliance In General", "% Compliance ALDI"];
} else if (GlobalsService.isHofer) {
  headerRow = ["Factory Name", "Aldi Süd KG ID", "Date of inventory", "Aldi Süd KG Number of chemical", "Consumption", "Total Production Facility", "Aldi Süd KG Production"];
} else if (GlobalsService.isInditex) {
    headerRow = ["CIL Supplier", "Factory Name", "Factory Country", "ZDHC AID", "Inditex Brand", "Reporting month/year", "Chemical supplier name", "Chemical Supplier Country", "Chemical product", "ZDHC Use category", "ZDHC Use type", "Type of manufacturing", "Consumption (kg)", "ZDHC Certification Level", "'The List by Inditex' certification level (Baby)", "'The List by Inditex' certification level (Adult)", "Chemical substance", "Maximum Concentration of Substance %", "CAS Number", "Toxicity ECHA", "Toxicity SVHC", "Toxicity other regulatory list"];
} else {
  headerRow = ["ZDHC AID", "Factory Name", "Contact Person", "Email", "Phone", "Vendor ID", "Higg ID", "Mill ID", "Activity", "Country Name", "Inventory Date", "Nr of Chemicals", "Cap for Chemicals", "Compliance", "Has Uploaded Wastewater", "Has Uploaded Incheck", "Has Uploaded Higg", "Last Upload Date"];
}

  const headerCells = worksheet.addRow(headerRow);
  headerCells.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
    pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
  };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  json.forEach((d: any) => {
    let row = worksheet.addRow(Object.values(d));
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
  });
}



  public exportCandAasExcelFile(json1, json2, json3, json4, json5, json6, excelFileName) {
    const workbook = new Workbook();

    const addWorksheet = (titles, tables, title, customHeadersList) => {
      const worksheet = workbook.addWorksheet(title);

      for (let i = 0; i < tables.length; i++) {
        const customHeaders = customHeadersList[i];
        const data = tables[i];
        if (titles.length > 0) {
        let titleRow = worksheet.addRow([titles[i]]);
          titleRow.eachCell(cell => {
            cell.font = { bold: true, size: 16 };
          });
        }
        let headerRow = worksheet.addRow(customHeaders);
        headerRow.eachCell(cell => {
          cell.font = { bold: true, size: 12 };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F2F2F2' },
            bgColor: { argb: '' }
          };
        });

      data.forEach((d: any) => {
        worksheet.addRow(Object.values(d));
      });

        worksheet.addRow([])
        ; // Add an empty row between tables
      }
      const columnWidth = 50; // Adjust the width as needed
      worksheet.columns.forEach(column => {
        column.width = columnWidth;
      });
      
    };
    

    const headers1 = ["Factory Name", "Country", "Month Of Inventory", "Total Formula Count", "Compliant Formula Count", "Count Compliance Percentage", "Brand Formula Count (used for C&A production)", "Compliant Brand Formula Count  (used for C&A production)", "Brand Count Compliance Percentage  (used for C&A production)", "Total Consumption", "Compliant Consumption", "Consumption Compliance Percentage", "Brand Consumption  (used for C&A production)", "Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Compliance Percentage  (used for C&A production)"];
    const headers2 = ["Factory Name", "Country", "Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers3 = ["Country", "Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers4 = ["Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers5 = ["Factory Name", "Chemical Name", "Chemicals Manufacturer"];
    const headers6 = ["Factory Name", "Chemical Product", "ZDHC Level", "Version", "Compliance Status", "Gots", "Eco Passport", "Bluesign", "C2C", "GreenScreen", "Toxfmd Scivera", "Test Report"];

    addWorksheet([],[json1], 'Compliance Overview', [headers1]);
    const titles = ["Unverified Chemicals by Factory", "Unverified Chemicals by Country", "Unverified Chemicals Globally", "Unverified Chemical List"]
    const customHeadersList = [headers2, headers3, headers4, headers5];
    const tables = [json2, json3, json4, json5];

    addWorksheet(titles, tables, 'Unverified Chemicals', customHeadersList);

    addWorksheet([],[json6], 'Complete List of Chemicals', [headers6]);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = excelFileName + '.xlsx';
      link.click();
    });
  }

  public exportRawDataAsExcelFile(json: any[], excelFileName: string, isBrandZdhc: boolean): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    if (isBrandZdhc) {
      XLSX.utils.sheet_add_aoa(myworksheet, [["ZDHC AID", "Higg ID", "Supplier ID", "Factory Name", "Date of Scan", "database formula / manually added", "Compliance Status", "Chemical Product Name", "Chemical Manufacturer", "Gateway Product Name", "Gateway manufacturer Name", "Gateway Level", "ZDHC Use Category (The BHive database)", "Applications / Use Areas (ZDHC use category)", "Category", "Delivered Quantity", "Consumption", "Stock Volume", "Commodity", "Product Type", "Batch / Lot Number", "CAS No.",
        "Notes", "Use of PPE", "GHS Classification", "Precautionairy Statement", "GHS MSDS available", "Stock location", "Storage condition", "Minimum stock", "Maximum stock", "Expiry date", "Manufacturing date", "Purchase Date", "Used for " + excelFileName, "ZDHC MRSL", "ZDHC Level", "ZDHC MRSL Version", "ZDHC MRSL Self Declaration", "GOTS", "GOTS version", "ECO PASSPORT", "Bluesign", "Bluesign System Partner", "Green Screen", "Green Screen level", "C2C", "The List > 3 Years", "The List < 3 Years", "Scivera", "ToxFMD", "Test Report", "Testing Facility", "SVHC", "Hazard statement", "Usage / Delivery" ]], { origin: "A1" });
      
    } 
    else {
      XLSX.utils.sheet_add_aoa(myworksheet, [["ZDHC AID", "Higg ID", "Supplier ID", "Factory Name", "Date of Scan", "database formula / manually added", "Compliance Status", "Chemical Product Name",
        "Chemical Manufacturer", "ZDHC Use Category (The BHive database)", "Applications / Use Areas (ZDHC use category)", "Category", "Delivered Quantity", "Consumption", "Stock Volume", "Commodity", , "Product Type", "Batch / Lot Number", "CAS No.",
        "Notes", "Use of PPE", "GHS Classification", "Precautionairy Statement", "GHS MSDS available", "Stock location", "Storage condition", "Minimum stock", "Maximum stock", "Expiry date", "Manufacturing date", "Purchase Date", "Used for " + excelFileName, "ZDHC MRSL", "ZDHC Level", "ZDHC MRSL Version", "ZDHC MRSL Self Declaration", "GOTS", "GOTS version", "ECO PASSPORT", "Bluesign", "Bluesign System Partner", "Green Screen", "Green Screen level", "C2C", "The List > 3 Years", "The List < 3 Years", "Scivera", "ToxFMD", "Test Report", "Testing Facility", "SVHC", "Hazard statement"]], { origin: "A1" });
    }

    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
    XLSX.writeFile(myworkbook, excelFileName + ' Raw Data' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }

  public exportAsExcelFileFactoryAnalytics(json: any[], excelFileName: string): void {
    var ws = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, json);
    ws['!cols'] = [
      {
        "hidden": false
      },
      {
        "hidden": true
      }
    ]
    var myworkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myworkbook, ws, "Sheet1");
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }


  public exportInventoryAsExcelFile(json: any[], excelFileName: string, inventoryData: string, zdhcGatewaySubscriptionStatus: boolean, isBrandZdhcMember: Boolean, isBrand: Boolean, loggedInBrandName: string, isRedNice: Boolean): void {

    // if (this.isSupplier) {
    const modifiedJson = json.map((item: any) => {
      const usedForCustomerArray = Array.isArray(item["usedForCustomer"]) ? item["usedForCustomer"].join(", ") : "";
      return {
        ...item,
        "usedForCustomer": usedForCustomerArray,
      };
    });
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedJson);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    if ((zdhcGatewaySubscriptionStatus === true && this.isSupplier) || (isBrandZdhcMember && isBrand && zdhcGatewaySubscriptionStatus)) {
      if (isBrand) {
        if (!isRedNice)
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });
      
      else
        XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "The list <3", "The list >3", "Bluesign approved",
          "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
          "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
          "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
          "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
          "GHS SDS available", "GHS classification", "Notes",
          "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
          "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
        "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });
      }
      else {
        if (!isRedNice)
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });
      
        else
        XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "In Cap", "The list <3", "The list >3", "Bluesign approved",
          "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
          "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
          "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
          "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
          "GHS SDS available", "GHS classification", "Notes",
          "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
          "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
          "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });
      }
    }
    else {
      if (isBrand) {
        if (!isRedNice)
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", loggedInBrandName + " Compliance", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });

        else
        XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "The list <3", "The list >3", "Bluesign approved",
          "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
          "Screened Chemistry", "SVHC", loggedInBrandName + " Compliance", "ZDHC CrowsSourced", "Purchase Date",
          "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
          "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
          "GHS SDS available", "GHS classification", "Notes",
          "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
          "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
          "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });

      }
      else {
        if(!isRedNice)
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });

        else
        XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "In Cap", "The list <3", "The list >3", "Bluesign approved",
          "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
          "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
          "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
          "Maximum stock", "Stock Location", "Storage Condition", "Batch/Lot number", "General usage in factory(Factory specific)",
          "GHS SDS available", "GHS classification", "Notes",
          "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
          "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
          "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });

      }
    }

    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Inventory' + ' ' + inventoryData);
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });

  }

  public exportStepDashboardAsExcelFile(dashboardData: any[], chemicalData: any[], substanceData: any[], month: string, year: string, factoryName: string): void {
    let workbook = new Workbook();
    // fill first worksheet
    let worksheet1 = workbook.addWorksheet('Dashboard Data');
    if (dashboardData.length > 0) {
      worksheet1.mergeCells('A1', 'K1');
      let factory_name = worksheet1.getCell('A1');
      factory_name.value = 'Performance: ' + factoryName;
      factory_name.font = {
        name: 'Calibri',
        size: 20,
        bold: true,
      };
      factory_name.alignment = { vertical: 'middle', horizontal: 'center' };
      factory_name.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      factory_name.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F2F2F2' },
        bgColor: { argb: '' }
      };
      worksheet1.mergeCells('A2', 'K2');
      let date = worksheet1.getCell('C2');
      if (month.includes('Summary'))
        date.value = month
      else date.value = month + ' ' + year;
      date.font = {
        name: 'Calibri',
        italic: true,
        size: 12,
        bold: true,
      };
      date.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      date.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet1.addRow([]);
      let headerRow = worksheet1.addRow(Object.keys(dashboardData[0]));
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B7DEE8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          size: 12,
          bold: true,
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      let descriptions = ['', 'How many chemical products have been uploaded in the inventory summary of the selected time span.', 'DETOX TO Zero compliance (compliant with ZDHC MSRL and/ or ECO PASSPORT certified and/or STeP MRSL Self-Declaration)', '', 'Minimum and maximum stock', 'Application/Used Areas (ZDHC use category)', 'Storage Location', 'Composition of the substance', 'GHS Classifications', 'H-Phrases', 'P-Phrases']
      let descriptionRow = worksheet1.addRow(descriptions);
      descriptionRow.height = 80;
      descriptionRow.alignment = { vertical: 'middle', horizontal: 'center' };
      descriptionRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DBEEF4' },
          bgColor: { argb: '' }
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.font = {
          size: 12,
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'left',
          vertical: 'middle',
        };
      });

      worksheet1.mergeCells('A4:A5');
      worksheet1.getCell('A4').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet1.columns = [
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 }
      ];
      dashboardData.forEach((d: any) => {
        let row = worksheet1.addRow(Object.values(d));
        row.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      worksheet1.addRow([]);
    }

    //fill second worksheet
    let worksheet2 = workbook.addWorksheet('Chemical Details');
    if (chemicalData.length > 0) {
      let headerRow2 = worksheet2.addRow(Object.keys(chemicalData[0]));
      headerRow2.eachCell(cell => {
        cell.font = { bold: true }
      })
      chemicalData.forEach((d: any) => {
        let row = worksheet2.addRow(Object.values(d));
      });
      worksheet2.addRow([]);
      worksheet2.columns = [
        { header: 'Factory Name', width: 32 },
        { header: 'Chemical Name', width: 32 },
        { header: 'Manufacturer', width: 32 },
        { header: 'Commodities', width: 32 },
        { header: 'Country', width: 32 },
        { header: 'Scan_Date', width: 32 },
        { header: 'Consumption', width: 32 },
        { header: 'Minimum Stock', width: 32 },
        { header: 'Maximum Stock', width: 32 },
        { header: 'Stock Location', width: 32 },
        { header: 'Factory Usage', width: 32 },
        { header: 'Step_Self_Declaration', width: 32 },
        { header: 'ZDHC_MRSL', width: 32 },
        { header: 'Eco_Passport', width: 32 },
        { header: 'Gateway Level', width: 32 },
        { header: 'GHS Classifications', width: 32 },
        { header: 'Precautionary Statement', width: 32 },
        { header: 'Hazard Statement', width: 32 }
      ];
    }

    // fill third worksheet
    let worksheet3 = workbook.addWorksheet('Substances');
    if (chemicalData.length > 0) {
      let headerRow3 = worksheet3.addRow(Object.keys(substanceData[0]));
      headerRow3.eachCell(cell => {
        cell.font = { bold: true }
      })
      substanceData.forEach((d: any) => {
        let row = worksheet3.addRow(Object.values(d));
      });
      worksheet3.addRow([]);
      worksheet3.columns = [
        { header: 'Chemical_Name', width: 32 },
        { header: 'Factory_Name', width: 32 },
        { header: 'Manufacturer', width: 32 },
        { header: 'CAS_Number', width: 32 },
        { header: 'Substance_Name', width: 32 },
        { header: 'Percentage', width: 32 },
      ];
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, factoryName + '_' + month + '_' + 'Oekotex_Data_Dash_Details' + '.xlsx');
    })
  }
  public exportKontoorReportAsExcelFile(data: any[], fileName: string) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Kontoor Monthly Report');
    if (data.length > 0) {
      let headerRow = worksheet.addRow(Object.keys(data[0]));
      headerRow.eachCell(cell => {
        cell.font = { bold: true }
      })
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F2F2F2' },
        bgColor: { argb: '' }
      };
      data.forEach((d: any) => {
        let row = worksheet.addRow(Object.values(d));
      });
      worksheet.properties.defaultColWidth = 32;
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fileName + '.xlsx');
      })

    }

  }
  public export_csvNikeFile(data: any[]) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const csvData = XLSX.utils.sheet_to_csv(worksheet, { FS: ',' }); // Set the field separator as a comma
    const dataBlob: Blob = new Blob([csvData], { type: 'text/csv' });
    const url: string = window.URL.createObjectURL(dataBlob);
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Chemistry Upload.csv';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
  public exportJsonInditex(json: any[], excelFileName: string) {
    try {
      const jsonData = JSON.stringify(json);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = excelFileName + '.json'; 
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting JSON:', error);
    }
  }

  public exportMilesReportAsExcelFile(data: any[], totals: any[], excelFileName: string): void {
    let workbook = new Workbook();
    let worksheet1 = workbook.addWorksheet('Miles Report');
    worksheet1.mergeCells('A1', 'C1');
    let factory_name = worksheet1.getCell('A1')
    factory_name.value = 'Factories Info'
    factory_name.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    factory_name.alignment = { vertical: 'middle', horizontal: 'center' };
    factory_name.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE699' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('D1', 'H1');
    let compliance = worksheet1.getCell('D1')
    compliance.value = 'General Compliance'
    compliance.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    compliance.alignment = { vertical: 'middle', horizontal: 'center' };
    compliance.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C6E0B4' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('I1', 'M1');
    let aldi_compliance = worksheet1.getCell('I1')
    aldi_compliance.value = 'ALDI Production (compliance)'
    aldi_compliance.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    aldi_compliance.alignment = { vertical: 'middle', horizontal: 'center' };
    aldi_compliance.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BDD7EE' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('N1', 'R1');
    let status = worksheet1.getCell('N1')
    status.value = 'Connection Status'
    status.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    status.alignment = { vertical: 'middle', horizontal: 'center' };
    status.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F8CBAD' },
      bgColor: { argb: '' }
    };
    let titles = ['Factory Name', 'Country', 'Inventory Date ', 'Compliance Level (%)', 'Number of Chemicals (tot)', 'Verified Count', 'Unverified Count', 'Commodities Count', 'Nr. Chemicals for ALDI (tot)', 'Verified Count', 'Unverified Count', 'Commodities Count', 'Compliance level (%)', 'Connected to MILES', 'Connection request sent on (date)', 'Status of the connection request', 'Connected to ALDI Einkauf', 'Connected to ALDI SÜD KG']
    let titlesRow = worksheet1.addRow(titles);
    titlesRow.eachCell((cell, index) => {
      if (index <= 3) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2CC' },
          bgColor: { argb: '' }
        };
      }
     else if (index > 3 && index <= 8) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E2EFDA' },
          bgColor: { argb: '' }
        };
      }
      if (index > 8 && index <= 13) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
          bgColor: { argb: '' }
        };
      }
      if (index > 13 && index <= 18) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FCE4D6' },
          bgColor: { argb: '' }
        };
      }
    });
    worksheet1.columns = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 }
    ];
    data.forEach((d: any) => {
      let row = worksheet1.addRow(Object.values(d));
    });
    let worksheet2 = workbook.addWorksheet('Total Compliance');
    worksheet2.columns = [
      {
        header: 'tot % compliance for all factories', width: 32},
          
      {
        header: 'Aldi Production Compliance', width: 32
      }
    ]
    worksheet2.getCell('A1').fill ={
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' },
            bgColor: { argb: '' }
    };
    worksheet2.getCell('B1').fill ={
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
      bgColor: { argb: '' }
    };;

    totals.forEach((d: any) => {
      let row = worksheet2.addRow(Object.values(d));
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName );
    })





  }


} 