import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { GlobalsService } from 'app/globals-services/globals.service';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import moment from 'moment';
import { ExportDataService } from 'app/shared/services/export-data.service';
import { ToastService } from 'app/common/toast-alert/toast.service';


@Component({
  selector: 'app-formula-pie-chart-dialog',
  templateUrl: './formula-pie-chart-dialog.component.html',
  styleUrls: ['./formula-pie-chart-dialog.component.scss']
})
export class FormulaPieChartDialogComponent implements OnInit {

  @ViewChild('factor_analytics_popup_tableMatPaginator') factor_analytics_popup_tableMatPaginator: MatPaginator;
  @ViewChild('factor_analytics_popup_tableMatSort') factor_analytics_popup_tableMatSort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  chosenStandard: Object = {};

  dialog_filters: Array<Object> = [];
  filters_brand = {};
  tabs: Array<Object> = [];
  newTabs: Array<Object> = [];
  segment: String[];
  tabIndex: number = 0;
  factor_analytics_popup_tableDataList = new MatTableDataSource<any>()
  isLoading = false;
  hasNotData: boolean = false;
  factor_analytics_popup_table = [];
  startDateString: string;
  endDateString: string;
  startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
  endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
  commoditiesControl = new FormControl(false);
  commoditiesValue: string[];
  isBrandZdhc: Boolean = GlobalsService.isBrandZdhcMember;
  includeGateway : Boolean = ((this.isBrand || this.isImporter) && this.isBrandZdhc) || !(this.isBrand || this.isImporter);
  loggedInBrandName: any;

  constructor(
    private dialogRef: MatDialogRef<FormulaPieChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cubejsService: CubejsService,
    private http: HttpClient,
    private globalsService: GlobalsService,
    private exportService: ExportDataService,
    private toastService: ToastService
  ) {
    this.factor_analytics_popup_table = this.includeGateway ?
      ["factoryName", "chemical_name", "manufacturer", "country", "scan_date", "gateway_subscription_status", "gateway_level", "basic_chemical", "zdhc_mrsl", "eco_passport", "toxfmd", "scivera", "test_report", "c2c", "bluesign", "gots", "inditex_AboveThreeYears", "inditex_BelowThreeYears", "zdhc_grading"]
      :
      ["factoryName", "chemical_name", "manufacturer", "country", "scan_date", "gateway_level", "basic_chemical", "zdhc_mrsl", "eco_passport", "toxfmd", "scivera", "test_report", "c2c", "bluesign", "gots", "inditex_AboveThreeYears", "inditex_BelowThreeYears", "zdhc_grading"]
    
  }

  get isBrand(): boolean { //is user role or organization type
    return this.globalsService.profile["organization_type"] == OrganizationType.BRAND
  }

  get isImporter(): boolean {
    return this.globalsService.profile["organization_type"] == OrganizationType.IMPORTER
  }

  ngOnInit(): void {
    // nameStandard is used to check which pichart is selected since chartData is an array which contains all chosen piechats
    this.loggedInBrandName = this.globalsService['profile']['uniqueIdentifier'] === 'ALDI_HOFER_BHIVE_ID' || this.globalsService['profile']['uniqueIdentifier'] === 'ALDI_NORTH_BHIVE_ID' ? 'ALDI Group' : this.globalsService['profile']['name']
    this.dialog_filters = [];
    this.dialog_filters.push(
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.data.start_Date,
          this.data.end_Date
        ]
      }
    )
    this.commoditiesValue = this.data.commodities == true ? ['0', '1'] : ['0']
    // tabs contain all categories of the chosen piechart. For each category, a new tab will be created
    this.chosenStandard = this.data.chartData.filter(el => el.name === this.data.nameStandard)[0]
    this.newTabs = this.data.chartData.filter(el => el.name === this.data.nameStandard)[0]['chartData']
    this.tabIndex = this.newTabs.findIndex(el => el['name']==this.data.event.name)
    this.tabInit()

  }

  ngAfterViewChecked(): void {
    if (this.table) {
      this.table.updateStickyColumnStyles();
    }
  }
  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  selectedTabChanged(event) {
    if (this.isLoading == true)
      return false;
    this.tabIndex = event.index;
    this.tabInit()
  }
  tabInit() {
    this.hasNotData = false;
    switch (this.chosenStandard['name']) {
      case 'zdhcGateway': {
        switch (this.tabIndex) {
          case 0: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.incheck_level1',
              "operator": 'equals',
              "values": ['1']
            });
            this.get_data();
            break;
          }
          case 1: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.incheck_level2',
              "operator": 'equals',
              "values": ['1']
            });
            this.get_data();
            break;
          }
          case 2: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.incheck_level3',
              "operator": 'equals',
              "values": ['1']
            });
            this.get_data();
            break;
          }
          case 3: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.incheck_registered',
              "operator": 'equals',
              "values": ['1']
            });
            this.get_data();
            break;
          }
          // case 4: {
          //   this.segment = [];
          //   this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
          //   this.dialog_filters.push({
          //     "member": 'Formulas.incheck_oldzdhc',
          //     "operator": 'equals',
          //     "values": ['1']
          //   });
          //   this.get_data();
          //   break;
          // }
          case 4: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            let add = {
              "and": [
                {
                  "member": 'Formulas.incheck_level1',
                  "operator": 'equals',
                  "values": ['0']
                },
                {
                  "member": 'Formulas.incheck_level2',
                  "operator": 'equals',
                  "values": ['0']
                },
                {
                  "member": 'Formulas.incheck_level3',
                  "operator": 'equals',
                  "values": ['0']
                },
                {
                  "member": 'Formulas.incheck_registered',
                  "operator": 'equals',
                  "values": ['0']
                },
                // {
                //   "member": 'Formulas.incheck_oldzdhc',
                //   "operator": 'equals',
                //   "values": ['0']
                // },
              ]
            }
            this.dialog_filters.push(add)
            this.get_data();
            break;
          }
        }
        break;
      }
      case 'theListAboveThreeYears': {
        switch (this.tabIndex) {
          case 0: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['A'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });

            this.get_data();
            break;
          }
          case 1: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['B'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 2: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['C'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });

            this.get_data();
            break;
          }
          case 3: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.bulk_commodities_cr"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_AboveThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  }
                ]
              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            else {
              this.segment = [];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_AboveThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  },
                  {
                    "member": 'Formulas.basic_chemical',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.factory_usage',
                    "operator": 'notEquals',
                    "values": ['Bulk / Commodities'],
                  }
                ]
              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            break;
          }
          case 4: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            let add = {
              "and":[
                {
                  "member": 'Formulas.inditex_AboveThreeYears',
                  "operator": 'equals',
                  "values": [''],
                },
                {
                  "member": 'Formulas.basic_chemical',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.factory_usage',
                  "operator": 'notEquals',
                  "values": ['Bulk / Commodities'],
                }
              ]
            }
            this.dialog_filters.push(add)
            this.get_data();

            break;
          }
        }
        break;
      }
      case 'theListBelowThreeYears': {
        switch (this.tabIndex) {
          case 0: {
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.segment = [];
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['A'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 1: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['B'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 2: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['C'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 3: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.bulk_commodities_cr"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_BelowThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  }
                ]

              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            else {
              this.segment = []
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_BelowThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  },
                  {
                    "member": 'Formulas.basic_chemical',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.factory_usage',
                    "operator": 'notEquals',
                    "values": ['Bulk / Commodities'],
                  }
                ]

              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            break;
          }
          case 4: {
            this.segment = []
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            let add = {
              "and": [
                {
                  "member": 'Formulas.inditex_BelowThreeYears',
                  "operator": 'equals',
                  "values": [''],
                },
                {
                  "member": 'Formulas.basic_chemical',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.factory_usage',
                  "operator": 'notEquals',
                  "values": ['Bulk / Commodities'],
                }
              ]

            }
            this.dialog_filters.push(add)
            this.get_data();
            break;
          }
        }
        break;
      }
      case 'inditexAboveThreeYears': {
        switch (this.tabIndex) {
          case 0: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['A'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });

            this.get_data();
            break;
          }
          case 1: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['B'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 2: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": ['C'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });

            this.get_data();
            break;
          }
          case 3: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.incheck_level3',
              "operator": 'equals',
              "values": ['1'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.inditex_AboveThreeYears',
              "operator": 'contains',
              "values": [''],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 4: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.bulk_commodities_cr"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  // {
                  //   "member": 'Formulas.incheck_level3',
                  //   "operator": 'equals',
                  //   "values": ['1'],
                  // },
                  {
                    "member": 'Formulas.inditex_AboveThreeYears',
                    "operator": 'contains',
                    "values": [''],
                  }
                ]
              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            else {
              this.segment = [];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_AboveThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  },
                  {
                    "member": 'Formulas.incheck_level3',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.basic_chemical',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.factory_usage',
                    "operator": 'notEquals',
                    "values": ['Bulk / Commodities'],
                  }
                ],
              };
              this.dialog_filters.push(add)
              this.get_data();
            }
            break;
          }
          case 5: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            let add = {
              "and": [
                {
                  "member": 'Formulas.inditex_AboveThreeYears',
                  "operator": 'equals',
                  "values": [''],
                },
                {
                  "member": 'Formulas.incheck_level3',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.basic_chemical',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.factory_usage',
                  "operator": 'notEquals',
                  "values": ['Bulk / Commodities'],
                }
              ],
            };
            this.dialog_filters.push(add)
            this.get_data();
            break;
          }
        }
        break;
      }
      case 'inditexBelowThreeYears': {
        switch (this.tabIndex) {
          case 0: {
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.segment = [];
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['A'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 1: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['B'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 2: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": ['C'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 3: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.dialog_filters.push({
              "member": 'Formulas.inditex_BelowThreeYears',
              "operator": 'contains',
              "values": [''],
            });
            this.dialog_filters.push({
              "member": 'Formulas.incheck_level3',
              "operator": 'equals',
              "values": ['1'],
            });
            this.dialog_filters.push({
              "member": 'Formulas.basic_chemical',
              "operator": 'equals',
              "values": ['0'],
            });
            this.get_data();
            break;
          }
          case 4: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.bulk_commodities_cr"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  // {
                  //   "member": 'Formulas.incheck_level3',
                  //   "operator": 'equals',
                  //   "values": ['1'],
                  // },
                  {
                    "member": 'Formulas.inditex_BelowThreeYears',
                    "operator": 'contains',
                    "values": [''],
                  }
                ]
              }
              this.dialog_filters.push(add)
              this.get_data();
            }
            else {
              this.segment = [];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              let add = {
                "and": [
                  {
                    "member": 'Formulas.inditex_BelowThreeYears',
                    "operator": 'equals',
                    "values": [''],
                  },
                  {
                    "member": 'Formulas.incheck_level3',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.basic_chemical',
                    "operator": 'equals',
                    "values": ['0'],
                  },
                  {
                    "member": 'Formulas.factory_usage',
                    "operator": 'notEquals',
                    "values": ['Bulk / Commodities'],
                  }
                ],
              };
              this.dialog_filters.push(add)

              this.get_data();
            }

            break;
          }
          case 5: {
            this.segment = [];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            let add = {
              "and": [
                {
                  "member": 'Formulas.inditex_BelowThreeYears',
                  "operator": 'equals',
                  "values": [''],
                },
                {
                  "member": 'Formulas.incheck_level3',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.basic_chemical',
                  "operator": 'equals',
                  "values": ['0'],
                },
                {
                  "member": 'Formulas.factory_usage',
                  "operator": 'notEquals',
                  "values": ['Bulk / Commodities'],
                }
              ],
            };
            this.dialog_filters.push(add)

            this.get_data();
            break;
          }
        }
        break;
      }
      case 'mixedZdhc': {
        switch (this.tabIndex) {
          case 0: {
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.segment = ["Formulas.mixed_zdhc_mrsl_crowd_sourced"];
            this.get_data();
            break;
          }
          case 1: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.mixed_zdhc_commodities"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.get_data();
            }
            else {
              this.segment = ["Formulas.mixed_zdhc_gateway"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.get_data();
            }
            break;
          }
          case 2: {
            if (this.data.commodities == true) {
              this.segment = ["Formulas.mixed_zdhc_gateway"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.get_data();
            }
            else {
              this.segment = ["Formulas.mixed_zdhc_unverified"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.get_data();
            }
            break;
          }
          case 3: {
            this.segment = ["Formulas.mixed_zdhc_unverified"];
            this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
            this.get_data();
            break;
          }
        }
        break;
      }
      default: {
        if (this.chosenStandard.hasOwnProperty('isBrand')) {
          switch (this.tabIndex) {
            case 0: {
              // if (this.chosenStandard.hasOwnProperty('brandFilters'))
              //   this.segment = [];
              // else
              this.segment = this.chosenStandard['hasBulkScope'] ? ["Formulas.compliant_with_commodities_cr"] : ["Formulas.compliant_cr"];
              this.dialog_filters = Array.from(this.data.overall_filters)
              this.get_data()
              break;
            }
            case 1: {
              let overall_filters2 = JSON.parse(JSON.stringify(this.data.overall_filters));
              // let brandF_array = {};
              // if (this.chosenStandard.hasOwnProperty('brandFilters')) {
              //   let brandF_array = Object.assign(this.data.brand_filters);
              //   let mapObj = {
              //     '"and"': '"or"',
              //     '"or"': '"and"',
              //     '"equals"': '"notEquals"',
              //     '"contains"': '"notContains"',
              //     // '0': "1"
              //   };
              //   let brandF_string = JSON.stringify(brandF_array).replace(/\"or\"|\"and\"|\"equals\"|\"notEquals\|\"contains\"|\"notContains\"/gi, function (matched) {
              //     return mapObj[matched];
              //   });
              //   let brandF = JSON.parse(brandF_string);
              //   let unverifiedFilters = [
              //     {
              //       "or":[
              //         {    
              //           "and": [
              //             {
              //               "member": 'Formulas.gateway_subscription_status',
              //               "operator": 'equals',
              //               "values": ['2']
              //             },
              //             {
              //               "and": [
              //                 {
              //                   "member": 'Formulas.incheck_level1',
              //                   "operator": 'notEquals',
              //                   "values": ['1']
              //                 },
              //                 {
              //                   "member": 'Formulas.incheck_level2',
              //                   "operator": 'notEquals',
              //                   "values": ['1']
              //                 },
              //                 {
              //                   "member": 'Formulas.incheck_level3',
              //                   "operator": 'notEquals',
              //                   "values": ['1']
              //                 }
              //               ]
              //             },
              //             this.data.commodities ? {//exclude commodities from this query, becuase they are calculated on the second query
              //               "and": [{
              //                 "member": 'Formulas.basic_chemical',
              //                 "operator": 'equals',
              //                 "values": ['0']
              //               },
              //                 brandF
              //               ]
              //             } : brandF
              //           ]                       
              //         },                 
              //         {
              //           "and": [
              //               {
              //                 "member": 'Formulas.gateway_subscription_status',
              //                 "operator": 'notEquals',
              //                 "values": ['2']
              //               },                           
              //             this.data.commodities ? {//exclude commodities from this query, becuase they are calculated on the second query
              //               "and": [{
              //                 "member": 'Formulas.basic_chemical',
              //                 "operator": 'equals',
              //                 "values": ['0']
              //               },
              //                 brandF
              //               ]
              //             } : brandF
              //           ]
              //         }
              //       ]
              //     }
              //   ]
              //   overall_filters2[0]['and'][2] = unverifiedFilters[0];
              //   this.segment = [];
              // } 
              // else 
              this.segment = this.chosenStandard['hasBulkScope'] ? ["Formulas.non_compliant_with_commodities_cr"] : ["Formulas.non_compliant_cr"];
              this.dialog_filters = [...overall_filters2];
              this.get_data()
              break;
            }
            case 2: {
              if (this.data.commodities == true) {
                this.segment = ["Formulas.bulk_commodities_cr"];

                this.get_data()
              }
              else {
                this.factor_analytics_popup_tableDataList.data = [];
                this.hasNotData = true;
              }
              break;
            }
          }
        }
        else {
          let standardName = this.chosenStandard['name'].replaceAll(/\s/g, '').replace(/[A-Z]/g, (letter, index) => { return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase() });
          let standardColumnName = "Formulas." + standardName;
          switch (this.tabIndex) {

            case 0: {
              this.segment = [];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.dialog_filters.push({
                "member": standardColumnName,
                "operator": 'equals',
                "values": ['1'],
              });
              this.dialog_filters.push({
                "member": 'Formulas.basic_chemical',
                "operator": 'equals',
                "values": ['0'],
              });
              this.get_data();
              break;
            }
            case 1: {
              this.segment = ["Formulas.non_compliant_cr"];
              this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
              this.dialog_filters.push({
                "member": standardColumnName,
                "operator": 'equals',
                "values": ['0'],
              });
              this.get_data();
              break;
            }
            case 2: {
              if (this.data.commodities == true) {
                this.segment = ["Formulas.bulk_commodities_cr"];
                this.dialog_filters = this.dialog_filters.filter((el) => el['member'] === "Formulas.scan_date")
                this.get_data();
              }
              else {
                this.factor_analytics_popup_tableDataList.data = [];
                this.hasNotData = true;
              }
              break;
            }
          }

        }

        break;
      }
    }
  }


  get_data() {
    let supplier = (this.isBrand || this.isImporter) && (this.data.supplierID && Number(this.data.supplierID) != 0) ? Number(this.data.supplierID) : null;
    this.factor_analytics_popup_tableDataList = new MatTableDataSource<any>([]);
    let dimensions = this.includeGateway ? [
      "Factories.id",
      "Formulas.formula_id",
      "Formulas.organization_name",
      "Formulas.country",
      "Formulas.chemical_name",
      "Formulas.chemical_manufacturer",
      "Formulas.scan_date",
      "Formulas.gateway_subscription_status",
      "Formulas.gateway_level",
      "Formulas.basic_chemical",
      "Formulas.zdhc_mrsl",
      "Formulas.eco_passport",
      "Formulas.toxfmd",
      "Formulas.scivera",
      "Formulas.test_report",
      "Formulas.c2c",
      "Formulas.bluesign",
      "Formulas.gots",
      "Formulas.inditex_AboveThreeYears",
      "Formulas.inditex_BelowThreeYears",
      "Formulas.zdhc_grading"
    ] : [
      "Factories.id",
      "Formulas.formula_id",
      "Formulas.organization_name",
      "Formulas.country",
      "Formulas.chemical_name",
      "Formulas.chemical_manufacturer",
      "Formulas.scan_date",
      "Formulas.gateway_level",
      "Formulas.basic_chemical",
      "Formulas.zdhc_mrsl",
      "Formulas.eco_passport",
      "Formulas.toxfmd",
      "Formulas.scivera",
      "Formulas.test_report",
      "Formulas.c2c",
      "Formulas.bluesign",
      "Formulas.gots",
      "Formulas.inditex_AboveThreeYears",
      "Formulas.inditex_BelowThreeYears",
      "Formulas.zdhc_grading"
    ] ;
    this.isLoading = true;
    const dialogQuery = {
      "measures": [],
      "dimensions": dimensions,
      "timeDimensions": [],
      "order": {
        "Formulas.organization_name": "asc"
      },
      "filters": this.dialog_filters,
      "segments": this.segment,
      "ungrouped": true
    }

    this.cubejsService.getCubeJSData(dialogQuery, supplier, this.data.standard_id, true, this.data.commodities ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          tempData = this.changeKey("Formulas.organization_name", "factoryName", tempData)
          tempData = this.changeKey("Formulas.country", "country", tempData)
          tempData = this.changeKey("Formulas.chemical_name", "chemical_name", tempData)
          tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
          tempData = this.changeKey("Formulas.scan_date", "scan_date", tempData)
          tempData = this.includeGateway ? this.changeKey("Formulas.gateway_subscription_status", "gateway_subscription_status", tempData) :tempData;
          tempData = this.changeKey("Formulas.gateway_level", "gateway_level", tempData);
          tempData = this.changeKey("Formulas.basic_chemical", "basic_chemical", tempData)
          tempData = this.changeKey("Formulas.zdhc_mrsl", "zdhc_mrsl", tempData)
          tempData = this.changeKey("Formulas.eco_passport", "eco_passport", tempData)
          tempData = this.changeKey("Formulas.toxfmd", "toxfmd", tempData)
          tempData = this.changeKey("Formulas.scivera", "scivera", tempData)
          tempData = this.changeKey("Formulas.test_report", "test_report", tempData)
          tempData = this.changeKey("Formulas.c2c", "c2c", tempData)
          tempData = this.changeKey("Formulas.bluesign", "bluesign", tempData)
          tempData = this.changeKey("Formulas.gots", "gots", tempData)
          tempData = this.changeKey("Formulas.inditex_AboveThreeYears", "inditex_AboveThreeYears", tempData)
          tempData = this.changeKey("Formulas.inditex_BelowThreeYears", "inditex_BelowThreeYears", tempData)
          tempData = this.changeKey("Formulas.zdhc_grading", "zdhc_grading", tempData)
          this.factor_analytics_popup_tableDataList.data = tempData;
          setTimeout(() => {
            this.factor_analytics_popup_tableDataList.paginator = this.factor_analytics_popup_tableMatPaginator;
            this.factor_analytics_popup_tableDataList.sort = this.factor_analytics_popup_tableMatSort
          }, 200);
          tempData = tempData.map(obj => {
            obj.scan_date = obj.scan_date.split('T')[0];
            if (!this.includeGateway) {
             if (obj.gateway_level) 
              obj.gateway_level = 'Yes';
              else obj.gateway_level = ''; 
            }
            if (obj.gateway_subscription_status === 1) {
              obj.gateway_subscription_status = 'Pending';
            }
            else {
              if (obj.gateway_subscription_status === 2) {
                obj.gateway_subscription_status = 'Active';
              }
              else {
                if (obj.gateway_subscription_status === 3) {
                  obj.gateway_subscription_status = 'Expired';
                }
                else {
                  if (obj.gateway_subscription_status === 4) {
                    obj.gateway_subscription_status = 'Declined'
                  }
                }
              }
            }
            return obj;
          });
        }
        else this.hasNotData = true;
        this.isLoading = false;
      })

  }
  exportData(data: any): void {
    if (data.length > 0)
      this.exportService.exportAsExcelFileFactoryAnalytics(data, this.factor_analytics_popup_tableDataList.data[0].factoryName + '_' + this.chosenStandard['name'] + '_' + this.chosenStandard['chartData'][this.tabIndex].name);
    else
      this.toastService.error("There is no data to export");
  }


}
