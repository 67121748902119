import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalNumberFormat'
})
export class ConditionalNumberFormatPipe implements PipeTransform {

  transform(value: any): any {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const firstElement = value[0];
        return this.formatNumber(firstElement);
      }
    } else {
      if (typeof value === 'number') {
      return this.formatNumber(value);
      }
      else if (typeof value === 'string' && value.includes(':')) {
        const originalDate = new Date(value);

        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = originalDate.getFullYear();

        const formattedDateString = `${year}-${month}-${day}`;
        return formattedDateString ;
      }
    }
    return value;
    
  }

  private formatNumber(value: number): any {
    const hasDecimalPart = value % 1 !== 0;
    return hasDecimalPart ? value.toFixed(1) : value;
  }
}
