<div class="dashboard-base">
    <div class="dashboard-select-bar">
        <mat-select #select [formControl]="dashboardControl" class="dashboard-select pt-2 pb-2 pl-5" style="width: 23%;">
               <mat-select-trigger style="display:flex; align-items:center">
                {{selectedDashboard?.name}}<mat-icon *ngIf="selectedDashboard?.is_home" style="color:white; margin-left:3px">home</mat-icon> 
                </mat-select-trigger>
            <mat-option *ngFor="let item of dashboardList" [value]="item.id"
                (onSelectionChange)="onDashboardChange($event)">
                {{item.name}}
                <span *ngIf="item.is_home" class="material-symbols-outlined" style="vertical-align:sub;">
                    home
                    </span>
            </mat-option>
        </mat-select>
    </div>
    <bhive-dashboard *ngIf="selectedDashboard" [selectedDashboard]="selectedDashboard" [organizationid]="organization_id"
        (onDashboardChanged)="refreshDashboardList($event)"></bhive-dashboard>
    <div *ngIf="!selectedDashboard" class="no-dashboard">
    </div>
</div>