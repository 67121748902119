    <!-- <div class="header">  -->
        <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon class="filter_icon">filter_list</mat-icon>
        </button>
    <!-- </div> -->
    <mat-menu #menu>
        <div class="menu-title">
            {{ columnName }}   
        </div>
        <div mat-filter-item class="table-filter-headers">Sort
            <button mat-button class="table-filter-sort-buttons" (click)="sortData('asc',columnName)" [class.active]="currentSortDirection === 'asc'"><mat-icon>arrow_upward</mat-icon>A-Z</button>
            <button mat-button class="table-filter-sort-buttons" (click)="sortData('desc', columnName)" [class.active]="currentSortDirection === 'desc'"><mat-icon>arrow_downward</mat-icon>Z-A</button>
        </div>
        <mat-divider></mat-divider>
        <div class="table-filter-headers">
            Filter & Search 
        </div>
        <div mat-filter-item class="table-filter-conditions">
            <mat-form-field style="margin-right:10px" class="table-filter-smaller-form-field">
                <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' [(value)]="searchCondition[columnName]">
                <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="table-filter-smaller-form-field">
                <input matInput placeholder="Value" [(ngModel)]="searchValue[columnName]">
            </mat-form-field>
        </div>
        <div mat-filter-item class="table-filter-search-and-filter">
            <mat-icon class="table-filter-search-and-filter-icon">search</mat-icon>
            <input type="text" [(ngModel)]="searchText" matTooltip="Search By {{ columnName }}" class="table-filter-search-and-filter-text" placeholder="Search By {{ columnName }}" (input)="onInputChange($event)" />
        </div>

        <!-- Search Table -->
        <div mat-filter-item class="table-filter-select-container">
            <table mat-table [dataSource]="filteredItems" class="table-filter-select">
            <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef> 
                    <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
                </ng-container>
            <!-- Values Column -->
                <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Select All</th>
                <td mat-cell *matCellDef="let element">{{element.value | conditionalNumberFormat }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height:unset;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height:unset;" (click)="selection.toggle(row)"></tr>
            </table>
        </div>

        <div mat-filter-item class="table-filter-apply-Search-Buttons">
            <button mat-raised-button (click)="clearColumn(columnName)" class="table-filter-select-buttons-Clear">Clear</button>
            <button color="primary" mat-raised-button (click)="applyFilter()" class="table-filter-select-buttons-Search">Apply</button>
        </div>
    </mat-menu>